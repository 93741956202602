export default [
	// {
	// 	title: 'Dashboard',
	// 	path: '/',
	// 	id: 'menu_1',
	// 	// sidebarIcon: <i className='fa fa-th-large' aria-hidden='true'></i>,
	// 	permission: 'view-dashboard',
	// },
	// {
	// 	// sidebarIcon: (
	// 	// 	<i className='material-icons-round fa fa-th-large' aria-hidden='true'></i>
	// 	// ),
	// 	title: `Dashboard`,
	// 	path: '/portfolio/dashboard',
	// 	id: 'menu_8',
	// 	permission: 'view-colender-dashboard',
	// },

	// {
	// 	title: 'Co-Lending',
	// 	path: '/manage-leads',
	// 	id: 'menu_2',
	// 	// sidebarIcon: <i className='fa fa-user-plus' aria-hidden='true'></i>,
	// 	subChilds: [
	// 		{
	// 			title: 'Manage Leads',
	// 			path: '/manage-leads',
	// 			// icon: <i className='fa fa-hand-pointer-o' aria-hidden='true'></i>,
	// 			permission: 'lead-listing',
	// 		},
	// 		{
	// 			// icon: <i className='fa fa-plus-circle' aria-hidden='true'></i>,
	// 			title: 'Add Lead',
	// 			path: '/lead/add',
	// 			permission: 'add-lead',
	// 		},
	// 	],
	// 	permission: 'manage-lead',
	// },
	// {
	// 	title: 'Manage Applications',
	// 	path: '/manage-applications',
	// 	id: 'menu_2',
	// 	// sidebarIcon: <i className='fa fa-user-plus' aria-hidden='true'></i>,
	// 	permission: 'manage-dsa-lead',
	// 	// subChilds: [
	// 	// 	{
	// 	// 		title: 'Manage Leads',
	// 	// 		path: '/manage-leads',
	// 	// 		icon: <i className='fa fa-hand-pointer-o' aria-hidden='true'></i>
	// 	// 	},

	// 	// ]
	// },
	// {
	// 	path: '/affiliate/manage-users',
	// 	title: 'Manage ACL',
	// 	id: 'menu_3',
	// 	// sidebarIcon: <i className='fa fa-file-image-o' aria-hidden='true'></i>,
	// 	permission: 'manage-acl',

	// },
	// {
	// 	path: '/comission-report',
	// 	title: 'Affiliate Commission Reports',
	// 	id: 'menu_3',
	// 	// sidebarIcon: <i className='fa fa-file-image-o' aria-hidden='true'></i>,
	// 	permission: 'generate-partner-commission',
	// },
	// {
	// 	path: '/dsa/comission-report',
	// 	title: 'DSA Commission Reports',
	// 	id: 'menu_3',
	// 	// sidebarIcon: <i className='fa fa-file-image-o' aria-hidden='true'></i>,
	// 	permission: 'generate-dsa-commission',
	// },
	{
		title: 'Dashboard',
		sidebarIcon: <i className='item-icon fa fa-bar-chart mt-1' aria-hidden='true'></i>,
		// permission: 'view-colender-dashboard',
		path: '/portfolio/dashboard',
		id: 'dashboard',
		permission: 'lender-dashboard'

	},
	{
		path: '/portfolio/pre-sanction',
		title: 'Sanction Approval',
		id: 'Sanction Approval',
		sidebarIcon: <i className='item-icon fa fa-list mt-1' aria-hidden='true'></i>,
		permission: 'assigned-portfolio,sanction-portfolio,sanction-rejected',
		subChilds: [
			// {
			// 	// icon: <i className='fa fa-shopping-bag '></i>,
			// 	title: `Pre-Sanction Portfolio`,
			// 	path: '/portfolio/pre-sanction',
			// 	permission: 'pre-sanction-portfolio',
			// 	parent: 'Sanction Approval'
			// },
			// {
			// 	// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
			// 	title: 'Pre-Sanction Approved',
			// 	path: '/portfolio/pre-sanction-approve',
			// 	permission: 'pre-sanction-approved',
			// 	parent: 'Sanction Approval'
			// },
			// {
			// 	// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
			// 	title: 'Pre-Sanction Rejected',
			// 	path: '/portfolio/pre-sanction-rejected',
			// 	permission: 'pre-sanction-rejected',
			// 	parent: 'Sanction Approval'
			// },
			{
				// icon: <i className='fa fa-shopping-bag' aria-hidden='true'></i>,
				// title: `Assigned Portfolio`,
				title: `Pending`,
				path: '/portfolio/assigned',
				permission: 'assigned-portfolio',
				parent: 'Sanction Approval'
			},

			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Sanctioned',
				path: '/portfolio/approved',
				permission: 'sanction-portfolio',
				parent: 'Sanction Approval'
			},
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Rejected',
				path: '/portfolio/sanction-rejected',
				permission: 'sanction-rejected',
				parent: 'Sanction Approval'
			},
		],
	},
	{
		path: '/portfolio/approved',
		title: 'Disbursal Approval',
		id: 'Disbursal Approval',
		sidebarIcon: <i className='item-icon fa fa-thumbs-o-up mt-1' aria-hidden='true'></i>,
		permission: 'sanction-portfolio,disbursal-pending,disbursal-approval,disbursed-rejected',
		subChilds: [

			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Sanctioned',
				path: '/portfolio/approved',
				permission: 'sanction-portfolio',
				parent: 'Disbursal Approval'
			},
			{
				// icon: <i className='fa fa-tags' aria-hidden='true'></i>,
				title: 'Disbursal Pending',
				path: '/portfolio/disbursal-pending',
				permission: 'disbursal-pending',
				parent: 'Disbursal Approval'
			},
			// {
			// 	icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
			// 	title: 'In-Disbursal Approval',
			// 	path: '/portfolio/in-disbursal-approve',
			// 	permission: 'disbursed-portfolio',
			// },
			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				// title: 'In-Disbursal Approval',
				title: 'Disbursal Approval',
				path: '/portfolio/in-disbursal-approve',
				permission: 'disbursal-approval',
				parent: 'Disbursal Approval'
			},

			{
				// icon: <i className='fa fa-file-text-o' aria-hidden='true'></i>,
				title: 'Disbursal Rejected',
				path: '/portfolio/disbursal-rejected',
				permission: 'disbursed-rejected',
				parent: 'Disbursal Approval'
			},
		],
	},
	{
		sidebarIcon: <i className='item-icon fa fa-thumbs-up mt-1' aria-hidden='true'></i>,
		title: 'Disbursed Portfolio',
		path: '/portfolio/disbursal-approved',
		permission: 'disbursed-portfolio',
		parent: 'Disbursed Portfolio'
	},
	{
		path: '/onboarding/details/partner',
		permission: 'dsa-onboarding-form',
		title: 'Onboarding Form',
		id: 'partner',
		// sidebarIcon: (
		// 	<i
		// 		className='item-icon material-icons-round fa fa-cog mt-1'
		// 		aria-hidden='true'
		// 	></i>
		// ),
	},
	{
		path: '/affiliate/kyc',
		title: 'Settings',
		id: 'Settings',
		// sidebarIcon: <i className='fa fa-cog' aria-hidden='true'></i>,
		subChilds: [
			{
				title: 'Profile',
				path: '/affiliate/kyc',
				parent: 'Settings'
				// icon: <i className='fa fa-hand-pointer-o' aria-hidden='true'></i>,
			},
			{
				title: 'Api Details',
				path: '/affiliate/api-details',
				parent: 'Settings'
				// icon: <i className='fa fa-hand-pointer-o' aria-hidden='true'></i>,
			},
		],
		permission: 'edit-kyc',
	},
	{
		sidebarIcon: <i className='item-icon material-icons-round fa fa-pinterest-p mt-1' aria-hidden='true'></i>,
		title: 'Post Disbursal Case View',
		path: '/portfolio/post-disbursal',
		permission: 'post-disbursal-case-view',
		parent: 'post-disbursal'
	},
	{
		title: 'Manage ACL',
		sidebarIcon: <i className='item-icon fa fa-users mt-1' aria-hidden='true'></i>,
		permission: 'manage-lender-acl',
		path: '/lender/manage-users',
		id: 'Manage ACL',
		subChilds: [
			{
				path: '/lender/roles',
				title: 'Manage Role',
				parent: 'Manage ACL'
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-hand-pointer-o mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
			{
				path: '/lender/role/add',
				title: 'Add Role',
				parent: 'Manage ACL'
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-plus-circle mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
			{
				path: '/lender/manage-users',
				title: 'Manage User',
				parent: 'Manage ACL'
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-hand-pointer-o mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
			{
				path: '/lender/manage-users/add',
				title: 'Add User',
				parent: 'Manage ACL'
				// icon: (
				// 	<i
				// 		className='item-icon material-icons-round fa fa-plus-circle mt-1'
				// 		aria-hidden='true'
				// 	></i>
				// ),
			},
		],
	},
];
