import React, { useState } from "react";
import ViewTabs from "../../../components/Portfolio/ViewTabs";
import PortfolioContainer from "../../../components/Portfolio/PortfolioContainer";
import "./index.css";
import Details from "./Details";
import Documents from "./Documents";
import QueryList from "./QueryList";
import Statemanage from "../../../config/StateContext";
import LenderDemand from "./LenderDemand";
import PaymentList from "./PaymentList";
import AdditionalInformation from "./AdditionalInfo";
import AuditTrail from "./AudtiTrail";
import Routing from "../../../router/Routing";
import { useParams, useLocation } from "react-router";
import { Context } from "../../../config/store";
import LenderUgroService from "../../../services/lender";
import MasterDataService from "../../../services/master-data";
import PortfolioActions from "./PortfolioActions";
import Button from "../../../components/Common/Button";
import { Link, useNavigate } from "react-router-dom";
import { objectsForEach } from "../../../common/objectToArray";
import DRF from "../DRF";
import UTRInfo from "./UTRInfo";
import { queries } from "@testing-library/react";
import ConditionalApprove from "./ConditionalApprove";
import FundOut from "./fundout";
import RepaymentDetails from "../PostDisbursalCaseView/RepaymentDetails";
import RepaymentSchedule from "../PostDisbursalCaseView/RepaymentSchedule";
export default function ViewPortfolio() {
  const { Buttonmanage } = React.useContext(Statemanage);
  const { state, dispatch } = React.useContext(Context);
  const [leadInfo, setLeadInfo] = React.useState();
  const [viewPage, setViewPage] = React.useState([]);
  const [lenders, setLenders] = React.useState([]);
  const [leadInfoError, setLeadInfoError] = React.useState(false);
  const [lenderInfoLoading, setLenderInfoLoading] = React.useState(false);
  const [leadInfoErrorMessage, setLeadInfoErrorMessage] = React.useState("");
  const [selectedLender, setSelectedLender] = React.useState(null);
  const [selectedLenderName, setSelectedLenderName] = React.useState(null);
  const [allSelected, toggleAllSelection] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [QueryLenderId, setQuerylenderId] = useState(null);
  const portfolioId = useParams()["id"];
  const [LAF, setLAF] = useState('')
  const params = useParams();

  const getLaf = async () => {
    try {
      let response = await MasterDataService.getLafId(
        "lender",
        portfolioId
      )
      if (response?.data?.status?.code === 1) {
        setLAF(response?.data?.data?.lead?.laf_id)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    getLaf()
  }, [])
  const getLenderList = async () => {
    setLeadInfoError(false);
    setLenderInfoLoading(true);
    try {
      const response = await MasterDataService.getLenderListByLeadId(
        "admin",
        "co-lender",
        portfolioId
      );
      if (response?.data?.status?.code === 1) {
        let responseList = objectsForEach(
          response.data.data.lender_list,
          "STRING_VAL"
        );
        let lenderItems = responseList.map((response) => ({
          label: response.value,
          value: response.id,
        }));
        setLenderInfoLoading(false);
        if (lenderItems.length === 0) {
          setLeadInfoError(true);
          setLeadInfoErrorMessage("No Lender Found");
        } else {
          setLenders(lenderItems);
          setSelectedLender(lenderItems[0].value);
          setSelectedLenderName(lenderItems[0].label);
        }
      }
    } catch (err) {
      setLeadInfoError(true);
      setLeadInfoErrorMessage("Server side error occured");
      setLenderInfoLoading(false);
    }
  };
  const getLeadDetail = async () => {
    const response = await LenderUgroService.getPortfolioDetail({
      type: state.userType,
      leadId: portfolioId,
      lenderId: allSelected ? 0 : selectedLender,
    });

    setLeadInfo(response?.data?.data?.details);
  };
  React.useEffect(() => {
    const currentLenderStatus = (leadInfo?.cl_lead_status || []).find(
      (status) => status.lender_id == selectedLender
    );
    const LeadTtpe = (leadInfo?.cl_lead_status || []).find(
      (status) => status.lender_id == selectedLender
    );

    const canViewAdditionalInfo =
      state.userType === "admin"
        ? leadInfo?.is_additional_info_req !== 2
        : leadInfo?.show_additional_info !== 0;
    let viewPages = [];
    if (location.pathname.includes('post-disbursal')) {
      viewPages = [
        {
          component: RepaymentDetails,
          path: '/repayment-details',
          title: 'Repayment Details',
          private: false,
        },
        {
          component: RepaymentSchedule,
          path: '/repayment-schedule',
          title: 'Repayment Schedule',
          private: false,
          customComponentProps: {
            selectedLender: selectedLender,
          },
        },
        {
          component: Documents,
          path: '/soa-documents',
          title: 'Statements',
          private: false,
          customComponentProps: {
            getLeadDetail: getLeadDetail,
            selectedLender: allSelected ? 0 : selectedLender,
            selectedLenderName: allSelected ? '' : selectedLenderName,
            cl_lead_lender_status: currentLenderStatus,
            isPostDisbursalCase: true
          },
        },
      ];
    } else {
      viewPages = [
        {
          component: Details,
          path: '/details',
          title: 'Details',
          private: true,
        }
      ];
      if (
        !canViewAdditionalInfo &&
        location.pathname.includes("additional") &&
        state.userType === "admin"
      ) {
        navigate(`/portfolio/view/${portfolioId}/details`);
      }
      if (currentLenderStatus) {
        viewPages = [
          ...viewPages,
          ...(currentLenderStatus.id > 1 && canViewAdditionalInfo
            ? [
              {
                component: AdditionalInformation,
                path: "/additional",
                title: "Additional Information",
                private: true,
                customComponentProps: {
                  getLeadDetail: getLeadDetail,
                  isAdditionalInfoRequired: leadInfo?.is_additional_info_req,
                  showAdditionalInfo: leadInfo?.is_additional_info_req,
                  cl_lead_lender_status: currentLenderStatus.id,
                  selectedLender: selectedLender,
                  selectedLenderName: selectedLenderName,
                },
              },
            ]
            : []),

          {
            component: Documents,
            path: "/documents",
            title: "Documents",
            private: true,
            customComponentProps: {
              getLeadDetail: getLeadDetail,
              selectedLender: allSelected ? 0 : selectedLender,
              selectedLenderName: allSelected ? "" : selectedLenderName,
              cl_lead_lender_status: currentLenderStatus.id,
            },
          },
          ...(currentLenderStatus.id > 3
            ? [
              {
                component: QueryList,
                path: "/queries",
                title: "Queries",
                private: true,
                customComponentProps: {
                  selectedLender: allSelected ? 0 : selectedLender,
                  selectedLenderName: selectedLenderName,
                },
              },
              {
                component: QueryList,
                path: "/queries/:queryId",
                private: true,
                customComponentProps: {
                  selectedLender: selectedLender,
                  selectedLenderName: selectedLenderName,
                },
              },
            ]
            : []),
          ...([6, 9, 10, 11, 15].includes(currentLenderStatus?.id)
            ? [
              !(leadInfo?.tab_config || []).includes('fundouts') && {
                component: FundOut,
                path: "/fundout",
                title: "UTR",
                private: true,
                customComponentProps: {
                  selectedLender: allSelected ? 0 : selectedLender,
                  selectedLenderName: selectedLenderName,
                }
              },
            ]
            : []),
          ...([6, 9, 10, 11, 15].includes(currentLenderStatus?.id)
            ? [
              !(leadInfo?.tab_config || []).includes('drf-info') && {
                component: DRF,
                path: "/drf-info",
                title: "DRF",
                private: true,
                customComponentProps: {
                  selectedLender: allSelected ? 0 : selectedLender,
                  selectedLenderName: allSelected ? "" : selectedLenderName,
                },
              },

            ]
            : []),
        ];

        viewPages = [
          ...viewPages,
          ...(leadInfo?.cl_lead?.lead_type == 1 && currentLenderStatus?.id > 1 && leadInfo?.cl_lead?.application_status === 'Pre-Sanctioned'
            ? [
              // {
              //   component: ConditionalApprove,
              //   path: "/conditional-approve",
              //   title: "Conditional Approve",
              //   private: true,
              //   customComponentProps: {
              //     selectedLender: allSelected ? 0 : selectedLender,
              //     selectedLenderName: allSelected ? "" : selectedLenderName,
              //   },
              // }
            ]
            : []),
        ];
      }
    }
    setViewPage(viewPages);
  }, [leadInfo, allSelected, location.pathname]);

  const updateLeadDetail = (leadInfo) => {
    setLeadInfo(leadInfo);
  };

  React.useEffect(() => {
    getLenderList();
  }, []);
  React.useEffect(() => {
    if (
      state.userType === "admin" &&
      location.pathname.includes("audit-trail")
    ) {
      toggleAllSelection(true);
    } else {
      toggleAllSelection(false);
    }
  }, [location.pathname]);
  React.useEffect(() => {
    if (selectedLender || allSelected) {
      getLeadDetail();
    }
  }, [selectedLender]);

  return (
    <PortfolioContainer>
      <div className="row">
        <div className='row no-gutters col-md-12 mb-3  p-2' style={{
          border: "1px solid #d5d5d5", borderBottom: "none", borderRadius: "4px", background: 'rgb(237,235,235)',
          background: 'linear-gradient(90deg, rgb(249,245,242) 0%, rgb(249,245,242) 39%, rgb(249,245,242) 3%, rgba(241,249,255,1) 100%)'
        }}>
          <div className='d-flex justify-content-between col-md-12'>
            <div className='d-flex'>
              {LAF && <div className='mr-1'>
                <div style={{ fontWeight: 600, fontSize: "12px", padding: "4px 16px 4px 6px", color: "#4A4E88", borderRadius: "0px", borderRight: '1px solid #8A8A8A' }}><span style={{ color: "black" }}>LAF : </span>{`${LAF}`}</div>
              </div>}
              <div className='ml-2'>
                <div style={{ fontWeight: 600, fontSize: "12px", padding: "4px 16px 4px 6px", borderRadius: "6px", color: "#4A4E88" }}><span style={{ color: "black" }}>Current Status :  </span>
                  <span style={{ border: "1px solid", borderRadius: "5px", padding: "2px 6px" }}>{leadInfo?.cl_lead_status && leadInfo?.cl_lead_status?.filter(status => status?.lender_id == selectedLender)[0]?.lender_status ? leadInfo?.cl_lead_status?.filter(status => status?.lender_id == selectedLender)[0]?.lender_status : 'Unassigned'}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-between no-gutters col-md-12 mb-2">
          <>
            <div className='d-flex'>
             <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: "fixed", top: "22px", cursor: "pointer", left: state.sidebarOpen ? "235px" : "60px"  }} onClick={() => navigate(state.selectedColender)}>
							<div style={{ border: "1px solid #4A4E88", borderRadius: "50%", color: "#4A4E88", padding: "1px 1px 1px 5px", justifyContent: "center", alignItems: "center", marginRight: "2px" }}>
								<i className='fa fa-chevron-left mr-1'></i>
							</div>
							<Link style={{ textDecoration:"underline", color: "#4A4E88", fontWeight: "600" }} className="backButton-top">BACK</Link>
						</div>
            </div>
            {location.pathname.includes('post-disbursal') &&
              <div style={{ cursor: 'pointer', color: '#007bff', fontSize: 16, marginRight: 0 }} onClick={() => {
                dispatch({
                  type: 'selectedColender',
                  selectedColender: '/portfolio/disbursal-approved',
                });
                localStorage.setItem('selectedColender', '/portfolio/disbursal-approved');
                navigate(`/portfolio/view/${portfolioId}/details`);
              }}
              >
                Check Application Details 
              </div>}

          </>

          {leadInfo && leadInfo.cl_lead_lender_status !== 11 && (
            <PortfolioActions
              state={state}
              detailsResponse={leadInfo}
              detailLenderId={selectedLender}
              detailLenderName={selectedLenderName}
              updateLeadDetail={updateLeadDetail}
            />
          )}
        </div>
        <div className="col-12">
          <ViewTabs
            portfolioId={portfolioId}
            tabs={viewPage.filter(({ title }) => title)}
          />
          <hr style={{marginTop: '-4.5px'}}/>
        </div>

        {state.userType === "admin" &&
          !location.pathname.includes("details") &&
          !location.pathname.includes("payment") &&
          !location.pathname.includes("drf-info") &&
          (location.pathname.includes("queries")
            ? params?.["*"] === "queries"
              ? true
              : false
            : true) && (
            <>
              <div className="col-md-2 mt-3">
                {!Buttonmanage && (
                  <select
                    value={allSelected ? 0 : selectedLender}
                    onChange={(e) => {
                      if (e.target.value == 0) {
                        toggleAllSelection(true);

                        setQuerylenderId(0);
                      } else {
                        setSelectedLender(e.target.value);

                        setQuerylenderId(e.target.value);
                        let selectedLenderName = lenders.find((lender) => {
                          return lender.value === e.target.value;
                        });
                        setSelectedLenderName(selectedLenderName.label);
                        toggleAllSelection(false);
                      }
                    }}
                    className="form-control"
                    placeholder=""
                  >
                    {state.userType === "admin" &&
                      location.pathname.includes("audit-trail") && (
                        <option value={0}>All</option>
                      )}

                    {state.userType === "admin" &&
                      (location.pathname.includes("audit-trail") ||
                        location.pathname.includes("documents") ||
                        location.pathname.includes("additional") ||
                        location.pathname.includes("utr-info") ||
                        location.pathname.includes("lender-demand")) &&
                      (lenders || []).map((lender) => (
                        <option key={lender?.value} value={lender?.value}>
                          {lender?.label}
                        </option>
                      ))}

                    {state.userType === "admin" &&
                      location.pathname.includes("queries") && (
                        <option value={0}>Admin</option>
                      )}

                    {state.userType === "admin" &&
                      location.pathname.includes("queries") &&
                      (lenders || []).map((lender) => (
                        <option key={lender?.value} value={lender?.value}>
                          {lender?.label}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </>
          )}

        <div class="col-md-12">
          <Routing routes={viewPage} />
        </div>
      </div>
    </PortfolioContainer>
  );
}
