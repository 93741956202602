import React from 'react';
import PortfolioContainer from '../../../../components/Portfolio/PortfolioContainer';
import LenderUgroService from '../../../../services/lender';
import { Context } from '../../../../config/store';
import { Link, useParams, useNavigate } from 'react-router-dom';
import message from '../../../../constant/message';
import { toast } from 'react-toastify';
import {
	objectsForEach,
	getAddInfoArray,
} from '../../../../common/objectToArray';
import Section from '../../../../components/AddLead/Section';
const AdditionalInformation = ({
	getLeadDetail,
	selectedLender,
	cl_lead_lender_status,
	isAdditionalInfoRequired,
	showAdditionalInfo,
}) => {
	const [leadId, setLeadId] = React.useState('');
	const [infoId, setInfoId] = React.useState('');
	const [leadData, setLeadData] = React.useState({});

	const [addInfoConfig, setAddInfoConfig] = React.useState({});
	const [addInfoConfigCopy, setAddInfoConfigCopy] = React.useState({});
	const [body, setBody] = React.useState({});
	const { state } = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const [getError, setGetError] = React.useState(false);
	const [getLoading, setGetLoading] = React.useState(false);
	const [mastersData, setMastersData] = React.useState({});
	const params = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		setLeadId(params.id);
		getLeadAdditionalInfo(params.id);
	}, [selectedLender]);
	const saveAdditionalInformation = async (e) => {
		if (!isAdditionalInfoRequired) {
			return navigate('/portfolio/view/' + leadId + '/documents');
		}
		e.preventDefault();
		setLoading(true);
		const requestBody = { ...leadData, ...body };
		let response;
		try {
			response = await LenderUgroService.addAdditionalInformation({
				type: state.userType,
				leadId: leadId,
				infoId: infoId,
				lenderId: selectedLender,
				requestBody,
			});

			if (response?.data?.status?.code === 1) {
				const success = response?.data?.messages?.success;

				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
				);
				await getLeadDetail();
				navigate('/portfolio/view/' + leadId + '/documents');
			}
		} catch (err) {
			let error = err?.response?.data?.messages?.error;

			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
			setErrors(err?.response?.data?.data.err_data);
		}

		setLoading(false);
	};
	const getLeadAdditionalInfo = async (leadId) => {
		setGetError(false);
		setGetLoading(true);
		try {
			const response = await LenderUgroService.getAdditionalInformation({
				type: state.userType,
				params: { cl_lead_id: leadId, lender_id: selectedLender },
			});
			setInfoId(response?.data?.data?.addInfo?.add_info_id);
			const responseAddInfoData = response?.data?.data?.addInfo?.res_json;
			const landerConfigData = response?.data?.data?.landerConfig;
			const collateral_details = (
				landerConfigData?.['collateral_details'] || []
			).map((collateral, index) => {
				return {
					CollatralId: collateral.CollatralId.rule.value,
					PropertyAddress: collateral?.PropertyAddress?.rule?.value,
					ModelDescription: collateral?.ModelDescription?.rule?.value,
					...(responseAddInfoData?.collateral_details?.[index] || {}),
				};
			});

			const coapplicant_details = (
				landerConfigData?.['coapplicant_details'] || []
			).map((coapplicant, index) => {
				return {
					CoapplicantName: coapplicant.CoapplicantName.rule.value,
					CoapplicantPan: coapplicant?.CoapplicantPan?.rule?.value,
					...(responseAddInfoData?.coapplicant_details?.[index] || {}),
				};
			});

			const busniess_details = {
                UdyamAadharNo: landerConfigData?.business_additional_info?.UdyamAadharNo?.rule?.value,
                UdyamIssueDate: landerConfigData?.business_additional_info?.UdyamIssueDate?.rule?.value,
                UdyamNICCode: landerConfigData?.business_additional_info?.UdyamNICCode?.rule?.value,
				Industry: landerConfigData?.business_additional_info?.Industry?.rule?.value,
                ...(responseAddInfoData?.business_additional_info || {}),
            }
			setBody(
				{
					...responseAddInfoData,
					business_additional_info: busniess_details,
					collateral_details: collateral_details,
					coapplicant_details: coapplicant_details,
				} || {}
			);
			setAddInfoConfig(response?.data?.data?.landerConfig || {});
			setAddInfoConfigCopy(response?.data?.data?.landerConfig || {});
			setMastersData(response?.data?.data?.masters || {});
			setGetLoading(false);
		} catch (err) {
			setGetError(true);
			setGetLoading(false);
		}
	};

	const getOptionsData = (mst_key) => {
		let actualMasters = mastersData[mst_key] ?? [];
		let actualOptions = {};
		actualMasters.map(({id, name}) => {
			actualOptions[name] = id;
		});
		return actualOptions;
	};

	const getOptionsDataForSlave = (mst_key, val) => {
		let actualMasters2 = (mastersData?.[mst_key] || []).filter(data => data.id == val);
		let actualOptions2 = {};
		(actualMasters2?.[0]?.slave || []).map(({id, name}) => {
			actualOptions2[name] = id;
		});
		return actualOptions2;
	}

	React.useEffect(() => {
        changeAddInfoConfig(body)
    }, [body, addInfoConfigCopy, mastersData]);

	const changeAddInfoConfig = (new_body) => {
		let temp_config = JSON.parse(JSON.stringify(addInfoConfigCopy));
		let deleted_key_arr = [];
		Object.keys(temp_config).map(p_key => {
			Object.entries(temp_config[p_key]).map((c_key) => {
				if (!(c_key[0] == 'title' || c_key[0] == 'type')) {
					let v = c_key[0];
					let dependency_field = c_key[1]?.['dependency_field'];
					let repulsion_input_value = new_body?.[p_key]?.[dependency_field] ?? '';
					let repulsion_value = (c_key[1]?.['repulsion_value'] || []);
					let on_change = temp_config[p_key][v]?.['on_change'] ?? '';
					let masters = temp_config[p_key][v]?.['masters'] ?? '';
					if(masters){
						temp_config[p_key][v]['rule']['options'] = getOptionsData(masters);
					}
					if(on_change){
						let current_selected_value = new_body?.[p_key]?.[v] ?? '';
						temp_config[p_key][on_change]['rule']['options'] = getOptionsDataForSlave(masters, current_selected_value);
					}
					if (repulsion_value.includes(repulsion_input_value || '')) {
						deleted_key_arr.push(v);
						delete temp_config[p_key][v];
					}
				}
			})
		})
		// setBody(new_body);
		setAddInfoConfig(temp_config);
	}

	return (
		<PortfolioContainer>
			{getLoading ? (
				<div className='col-sm-12'>
					<div className='Tableloader' style={{ margin: "10% auto auto auto" }}></div>
				</div>
			) : (
				<div className='addInfoContainer'>
					<form onSubmit={saveAdditionalInformation}>
						{getAddInfoArray(addInfoConfig || []).map(
							({ title, type, id, add_more, ...rest }) => (
								<Section
									key={id}
									title={title}
									id={id}
									errors={errors}
									body={body}
									leadId={leadId}
									setBody={setBody}
									type={type}
									parameters={rest}
									edit={
										isAdditionalInfoRequired &&
										state.userType === 'admin' &&
										showAdditionalInfo !== 0
									}
									showAddMore={false}
								/>
							)
						)}

						{state.userType === 'admin' &&
							showAdditionalInfo !== 0 &&
							!getError &&
							!getLoading && (
								<div className='mt-4'>
									<button
										disabled={loading}
										type='submit'
										className='btn btn-custom'
									>
										{loading ? (
											<div className='loader' />
										) : cl_lead_lender_status === 2 ||
										  cl_lead_lender_status === 3 ? (
											'Save Additional Info'
										) : (
											'Save Additional Info'
										)}
									</button>
									<Link
										to={'/portfolio/unassigned'}
										className='btn btn-close ml-2'
									>
										Cancel
									</Link>
								</div>
							)}
						{getError && (
							<p className='text-center error-text'>
								Failed to load Additional Information
							</p>
						)}
						{getLoading && (
							<div className='d-flex w-100 justify-content-center'>
								<div className='loader' />
							</div>
						)}
					</form>
				</div>
			)}
		</PortfolioContainer>
	);
};

export default AdditionalInformation;
