import config from './config';
import APIService from './api-service';
import objectToQueryParams from '../common/objectToQueryParams';

/**
 * @param {String} email
 * @param  {String} password
 * @param {String} type
 */

export default class LenderUgroService extends APIService {
	static getFilteredPortfolio({
		type,
		leadType,
		page,
		dataExport,
		sortBy,
		sortOrder,
		queryParams,
		responseType,
	}) {
		return this.get(
			`${config.baseUrl[type]}/leads/${leadType}?page=${page}&export=${dataExport}&${queryParams}&sort_order=${sortBy}&sort_by=${sortOrder}`,
			null,
			responseType
		);
	}

	static getAdminFilteredPortfolio({
		type,
		leadType,
		page,
		dataExport,
		queryParams,
		responseType,
		sortOrder,
		sortBy,
		isOps,
	}) {
		return this.get(
			`${config.baseUrl[type]}/leads/${leadType}?page=${page}&export=${dataExport}&${queryParams}${sortOrder ? `&sort_order=${sortOrder}` : ''
			}${sortBy ? `&sort_by=${sortBy}` : ''}`,
			null,
			responseType
		);
	}

	static getPortfolioDetail({ type, leadId, lenderId }) {
		return this.get(
			`${config.baseUrl[type]}/${type === 'admin' ? 'co-lender/lead/detail' : 'lead'
			}/${leadId}${type === 'admin' ? '/' + lenderId : ''}`
		);
	}
	static getPortfolioNewDetail({ type, leadId }) {
		return this.get(
			`${config.baseUrl[type]}/${type === 'admin' ? 'co-lender/lead/detail-info' : 'lead/detail-info'
			}/${leadId}`
		);
	}

	static getLeadDetailInfo({ type, leadId }) {
		return this.get(`${config.baseUrl[type]}/${'lead'}/${leadId}`);
	}
	static getPortfolioDocuments({ type, leadId, lenderId }) {
		return this.get(
			`${config.baseUrl[type]}/${type === 'admin' ? 'co-lender/' : ''
			}lead/documents/${leadId}${type === 'admin' ? `/${lenderId}` : ''}`
		);
	}

	static approveLead({ type, requestBody }) {
		return this.post(`${config.baseUrl[type]}/leads/approve`, requestBody);
	}

	static rejectLead({ type, requestBody }) {
		return this.post(`${config.baseUrl[type]}/leads/reject`, requestBody);
	}

	static addLeadQuery(type, formData, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
			console.log(formData);
		}
		return this.post(
			`${url}/lead/save-colender-lead-query`,
			formData,
			null,
			null,
			{
				'Content-Type': 'multipart/form-data',
			}
		);
	}

	static getCoLenders({ type, leadId }) {
		let url = `${config.baseUrl[type]}`;
		if (type === 'admin') {
			url += `/co-lender`;
		}
		return this.get(
			`${url}/lead/get-colender-lead-assign?cl_lead_id=${leadId}`
		);
	}
	static getLenderUsers({ type, leadId }) {
		return this.get(
			`${config.baseUrl[type]}/get-assign-lender-users/${leadId}`
		);
	}

	static assignLender({ type, requestBody }) {
		return this.post(
			`${config.baseUrl[type]}/co-lender/lead/assign-colender-lead`,
			requestBody
		);
	}
	static assignUserLead({ type, requestBody }) {
		return this.get(
			`${config.baseUrl[type]}/lead/assign-to-lender-user/${requestBody.cl_lead_id}/${requestBody.user_id}`
		);
	}
	static addAdditionalInformation({
		type,
		leadId,
		lenderId,
		infoId,
		requestBody,
	}) {
		return this.post(
			`${config.baseUrl[type]}/${type === 'admin' ? 'co-lender/' : ''
			}lead/save-additional-info?cl_lead_id=${leadId}${lenderId && type == 'admin' ? '&lender_id=' + lenderId : ''
			}`,
			requestBody
		);
	}
	static getAdditionalInformation({ type, params }) {
		return this.get(
			`${config.baseUrl[type]}/${type === 'admin' ? 'co-lender/' : ''
			}lead/get-additional-info?${objectToQueryParams(params)}`
		);
	}
	static getQueryDetails({ type, isChild, params }) {
		if (isChild)
			return this.get(
				`${config.baseUrl[type]}${type === 'admin' ? '/co-lender' : ''
				}/lead/get-colender-child-lead-query/${isChild}`
			);
		return this.get(
			`${config.baseUrl[type]}${type === 'admin' ? '/co-lender' : ''
			}/lead/get-colender-lead-query?${objectToQueryParams(params)}`
		);
	}

	static getPaymentToken(requestBody) {
		return this.post(
			'https://bob-api-uat.knightfintech.com/GetToken',
			requestBody,
			null,
			null,
			{},
			false
		);
	}

	static getPaymentList(leadId) {
		return this.get(
			`${config.baseUrl['admin']}/co-lender/lead/repayment-detail/${leadId}`
		);
	}

	static resolveQuery(type, formData) {
		return this.post(
			`${config.baseUrl[type]}/co-lender/lead/save-colender-lead-query`,
			formData,
			null,
			null,
			{
				'Content-Type': 'multipart/form-data',
			}
		);
	}

	static updateDocPassword(type, data, subType) {
		return this.post(
			`${config.baseUrl[type]}/${subType ? subType + '/' : ''
			}lead/document/update`,
			data
		);
	}

	/**
	 *
	 * @param {string} type
	 * @param {string} subType
	 * @param {string} docId
	 */
	static downloadDocFile(type, subType, docId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/lead/document/download?lead_doc_file_id=${docId}`,
			null,
			'blob'
		);
	}

	/**
	 *
	 * @param {string} type
	 * @param {string} subType
	 * @param {string} docId
	 */
	static downloadAttachmentFile(type, subType, docId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}

		return this.get(
			`${url}/lead/query/document/download/${docId}`,
			null,
			'blob'
		);
	}

	/**
	 *
	 * @param {string} type
	 * @param {string} subType
	 * @param {string} leadId
	 * @param {*} body
	 */
	static getLeadDemand(type, subType, leadId, body) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/lead/get-demand/${leadId}`, body);
	}

	/**
	 *
	 * @param {string} type
	 * @param {string} subType
	 * @param {*} body
	 */
	static toggleDocumentForLender(type, subType, body) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/lead/documents/submit-to-lender`, body);
	}
	/**
	 *
	 * @param {string} type
	 * @param {string} subType
	 * @param {string} leadId
	 * @param {*} body
	 */
	static postLeadDemand(type, subType, leadId, body) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/lead/post-demand/${leadId}`, body);
	}

	/**
	 *
	 * @param {*} type
	 * @param {*} subType
	 * @param {*} leadId
	 */
	static submitToLender(type, subType, leadId, detailLenderId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/submit-to-lender/${leadId}/${detailLenderId}`);
	}
	/**
	 *
	 * @param {*} type
	 * @param {*} subType
	 * @param {*} leadId
	 */
	static pullBackLead(type, subType, leadId, lenderId, comment) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/lead/pull-back/${leadId}`, {
			lender_ids: lenderId,
			message: comment,
		});
	}

	static getDashboardLenderPortfolio(prodId, date) {
		return this.get(
			`${config.baseUrl['admin']}/co-lender/dashboard/lender-portfolio?prod_type_id=${prodId}&date=${date}`
		);
	}

	static getDashboardLenderBar(type, subType, barType, prodId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio/${barType}?prod_type_id=${prodId}`
		);
	}

	static getDashboardTotalAssigned(type, subType, prodId, lenderId) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/total-assigned-portfolio?${lenderId ? 'lender_id=' + lenderId + '&' : ''
			}prod_type_id=${prodId}`
		);
	}

	static getDashboardLenderPerformance(prodId, date) {
		return this.get(
			`${config.baseUrl['admin']}/co-lender/dashboard/lender-performance?prod_type_id=${prodId}&date=${date}`
		);
	}
	static getLeadAuditTrail(leadId, lenderId, leadType) {
		return this.get(
			`${config.baseUrl['admin']}/get-audit-trail/${leadId}/${lenderId}?lead_type=${leadType}`
		);
	}

	static fetchDocuments(leadId) {
		return this.get(
			`${config.baseUrl['lender']}/lead/document/fetch-dms-document/${leadId}`
		);
	}

	static bulkLeadSubmit({ leadIds, lenderId, comment }) {
		let body = {
			cl_lead_ids: leadIds,
			lender_id: lenderId,
			comment: comment,
		};
		return this.post(
			`${config.baseUrl['admin']}/co-lender/lead/bulk-submit-lead`,
			body
		);
	}
	static getLeadAuditTrailHistory({ page, queryParams }) {
		return this.get(
			`${config.baseUrl['admin']}/co-lender/lead/bulk-submit-audit-trail?page=${page}&${queryParams}`
		);
	}

	/**
	 *
	 * @param {*} batchId
	 *
	 */
	static getLeadAuditTrailByBatch({ page, batchId }) {
		return this.get(
			`${config.baseUrl['admin']}/co-lender/lead/bulk-submit-audit-trail/${batchId}?page=${page}`
		);
	}
	static dashboardTotalAssignedPortfolio({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio?${objectToQueryParams(params)}`
		);
	}
	static dashboardLenderPending({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio/lender-pending-portfolio1?${objectToQueryParams(
				params
			)}`
		);
	}
	static dashboardLenderPending2({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio/lender-pending-portfolio2?${objectToQueryParams(
				params
			)}`
		);
	}
	static dashboardLenderPending3({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio/lender-pending-portfolio3?${objectToQueryParams(
				params
			)}`
		);
	}
	static dashboardUnassignedPortfolio({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/leads/unassigned?${objectToQueryParams(params)}`
		);
	}
	static dashboardFinallyAssignedPortfolio({ type, params, subType }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(
			`${url}/dashboard/lender-portfolio/finally-assigned?${objectToQueryParams(
				params
			)}`
		);
	}

	/**
	 *
	 * @param {string} type
	 * @param {object} body
	 * @returns Promise
	 */
	static approveOpsLead(type, body) {
		let url = config.baseUrl[type];

		return this.post(`${url}/lead/disbursal/approve`, body);
	}

	/**
	 *
	 * @param {string} type
	 * @param {object} body
	 * @returns Promise
	 */
	static modifyLeadOpsStatus(type, body, routes) {
		let url = config.baseUrl[type];

		return this.post(`${url}/lead/${routes}/change-status`, body, null, null, {
			'Content-Type': 'multipart/form-data',
		});
	}


	/**
	 *
	 * @param {*} type
	 * @param {*} formData
	 * @param {*} subType
	 * @returns
	 */
	static addRepaymentDoc(type, formData, subType) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.post(`${url}/lead/repayment/submit`, formData, null, null, {
			'Content-Type': 'multipart/form-data',
		});
	}
	static getUTRDetails({ type, subType, leadId }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/utr/${leadId}`);
	}
	static getfundOutDetail({ type, subType, leadId }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/fundout/${leadId}`);
	}
	static getConditionalApproveDetail({ type, subType, leadId }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/pre-sanction-params/${leadId}`);
	}
	static SanctionConditionDelete({ type, subType, pre_cond_id }) {
		let url = `${config.baseUrl[type]}`;
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/pre-sanction-params/delete/${pre_cond_id}`);
	}
	/**
	 *
	 * @param {string} type
	 * @param {object} body
	 * @returns Promise
	 */
	static addUTRInfo({ type, subType, formData }) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
			console.log(formData);
		}
		return this.post(`${url}/lead/utr/submit`, formData, null, null, {
			'Content-Type': 'multipart/form-data',
		});
	}

	/**
 *
 * @param {string} type
 * @param {object} body
 * @returns Promise
 */
	static ConditionalApprove({ type, subType, formData }) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
			console.log(formData);
		}
		return this.post(`${url}/lead/pre-sanction-params/submit`, formData, null, null, {
			'Content-Type': 'multipart/form-data',
		});
	}

	/**
	 *
	 * @param {string} type
	 * @param {object} body
	 * @returns Promise
	 */
	static getUserList({ type, page, queryParams, exportList }) {
		let url = config.baseUrl[type];

		return this.get(
			`${url}/backend-user?page=${page}&${queryParams}&export=${exportList ? 1 : 0
			}`,
			null,
			exportList ? 'blob' : 'json'
		);
	}
	static changeQueryStatus({ type, subType, queryId }) {
		let url = config.baseUrl[type];
		if (subType) {
			url += `/${subType}`;
		}
		return this.get(`${url}/lead/change-lead-query-status/${queryId}`);
	}

	static getRepaymentDetails({ type, cl_lead_id }) {
		return this.get(`${config.baseUrl[type]}/lead/get-lender-rps-detail/${cl_lead_id}`);
	}

	static getRePaymentList({ leadId, lenderId }) {
		return this.get(
			`${config.baseUrl["lender"]}/lead/get-lender-repayment/${leadId}/${lenderId}`
		);
	}

	static downloadRepaymentFile({ type, leadId, lenderId, dataExport, responseType }) {
		return this.get(
			`${config.baseUrl["lender"]}/lead/get-lender-repayment/${leadId}/${lenderId}?export=${dataExport}&type=${type}`, null, responseType
		);
	}

	static getAdditionalInfoAbbData({ type, add_info_cta_id }) {
		return this.get(`${config.baseUrl[type]}/lead/get-additional-info-cta?add_info_cta_id=${add_info_cta_id}`);
	}

	static getGlobalSearchList({ type, laf_id }) {
		return this.get(`${config.baseUrl[type]}/leads/search?laf_id=${laf_id}`);
	}
}
