import React from 'react';
import objectToQueryParams from '../../common/objectToQueryParams';

let isOutside = false;

const SearchFilter = ({ setQueryParams, roleList, businessType, userType }) => {

	const [loading, setLoading] = React.useState(false);
	let searchData = JSON.parse(sessionStorage.getItem('searchData'));
	const getParamsFromSessionStorage = (key, type = null) => {
		let searchDataResult = searchData ? searchData[key] : '';
		if (type === 'date' && searchDataResult) {
			let convertedDate = searchDataResult?.split('/')
			return new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`)
		}
		return searchDataResult;
	}

	const [name, setName] = React.useState(getParamsFromSessionStorage('name'));
	const [roleId, setRoleId] = React.useState(getParamsFromSessionStorage('role_id'));
	const [status, setStatus] = React.useState(getParamsFromSessionStorage('status'));
	const [employee_code, setEmployeeCode] = React.useState('');
	const [business_type, setBusinessType] = React.useState('');
	const [mobileNo, setMobileNo] = React.useState(getParamsFromSessionStorage('mobile_no'));
	const [email, setEmail] = React.useState(getParamsFromSessionStorage('email_id'));
	const searchFilterRef = React.useRef()
	const submitQueryParams = () => {
		setLoading(true);
		const searchObject = {
			name,
			status,
			role_id: roleId,
			mobile_no: mobileNo,
			email_id: email,
			...(userType === 'admin' && { employee_code, business_type })
		};
		sessionStorage.setItem('searchData', JSON.stringify({ ...searchObject, org_page: 1 }))
		const queryString = objectToQueryParams(searchObject);
		setQueryParams(queryString);
		setLoading(false);
	};
	const resetQueryParams = () => {
		sessionStorage.removeItem('searchData')
		setQueryParams('');
		setName('');
		setRoleId('');
		setStatus('');
		setBusinessType('');
		setEmployeeCode('');
		setEmail('');
		setMobileNo('');
	};

	React.useEffect(() => {
		const listener = event => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				if (isOutside) {
					document.getElementById("submitButton").click();
				}
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);

	React.useEffect(
		() => {
			const listener1 = (event) => {
				if (!searchFilterRef.current || searchFilterRef.current.contains(event.target)) {
					isOutside = true
					return;
				} else {
					isOutside = false
				}
			};
			document.addEventListener("mousedown", listener1);
			document.addEventListener("touchstart", listener1);
			return () => {
				document.removeEventListener("mousedown", listener1);
				document.removeEventListener("touchstart", listener1);
			};
		},
		[searchFilterRef, isOutside]
	);

	return (
		<div className='tab panel'>
			<input type='checkbox' id='accordion'></input>
			<label for='accordion' className='w-100 head_top tab-label'>
				<h4>Search Filter</h4>
			</label>
			<form className='tab-content' ref={searchFilterRef}>
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='form-group'>
								<label>User Name</label>
								<input
									value={name}
									onChange={e => setName(e.target.value)}
									type='text'
									className='form-control'
									placeholder='User Name'
								/>
							</div>
						</div>
						{/* <div className='col-md-4'>
							<div className='form-group'>
								<label>User Role</label>
								<select
									value={roleId}
									onChange={e => setRoleId(e.target.value)}
									className='form-control'
								>
									<option value={null}>Select Role</option>
									{roleList.map(({ value, id }) => (
										<option key={id} value={id}>
											{value}
										</option>
									))}
								</select>
							</div>
						</div> */}
						{userType === 'lender' && (
							<>
								{' '}
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Mobile No</label>
										<input
											value={mobileNo}
											onChange={e => setMobileNo(e.target.value)}
											type='text'
											className='form-control'
											placeholder='Mobile No'
										/>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Email Id</label>
										<input
											value={email}
											onChange={e => setEmail(e.target.value)}
											type='text'
											className='form-control'
											placeholder='Email id'
										/>
									</div>
								</div>
							</>
						)}
						{userType === 'admin' && (
							<>
								{' '}
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Employee Code</label>
										<input
											value={employee_code}
											onChange={e => setEmployeeCode(e.target.value)}
											type='text'
											className='form-control'
											placeholder='Employee Code'
										/>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='form-group'>
										<label>Business Type</label>
										<select
											value={business_type}
											onChange={e => setBusinessType(e.target.value)}
											className='form-control'
										>
											<option value={null}>Business Type</option>
											{(businessType || []).map(({ name, id }, index) => (
												<option value={id} key={index}>
													{name}
												</option>
											))}
										</select>
									</div>
								</div>
							</>
						)}

						<div className='col-md-4'>
							<div className='form-group'>
								<label>Status</label>
								<select
									value={status}
									onChange={e => setStatus(e.target.value)}
									className='form-control'
								>
									<option value={null}>Status</option>
									<option value={0}>In active</option>
									<option value={1}>Active</option>
								</select>
							</div>
						</div>
					</div>

					{/* <div className=''> */}
						<div className='row' style={{marginLeft: '1px',marginTop: '2px'}}>
							<button
								onClick={submitQueryParams}
								type='button'
								className='btn btn-custom'
								id='submitButton'
							>
							{loading ? <div className="loader"></div> : 'Search'}
							</button>
							<button
								onClick={resetQueryParams}
								type='button'
								className='btn btn-custom ml-2'
							>
								Reset All
							</button>
						</div>
					{/* </div> */}
				</div>
			</form>
		</div>
	);
};

export default SearchFilter;
