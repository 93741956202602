import React, { useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import LenderUgroService from '../../../services/lender';
import { Context } from '../../../config/store';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import message from '../../../constant/message';
import Button from '../../../components/Common/Button';

const MISApprovals = ({
	detailLenderId,
    leadId,
	detailsResponse
}) => {
	const navigate = useNavigate();
	const { state } = React.useContext(Context);
	const [loading, setLoading] = useState(false);
    const [modalState, setModalState] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [approvals, setAppprovals] = useState([]);

	const leadStatus = detailsResponse?.cl_lead_lender_status;
	const prodType = detailsResponse?.cl_lead?.prod_type;

	const splitUnderscoreToCaptialise = (word) => {
		return word.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
		  return ($1 && ' ') + $2.toUpperCase();
		});
	  };

    const toggleModal = (type)=>{
		setModalTitle(type);
        setModalState(!modalState);
    }
	const getApprovalType = (name) => {
		if(name === 'financial_approval')
			return 1;
		else if(name === 'legal_approval')
			return 2;
		else if(name === 'technical_approval')
			return 3;
		else
			return '';
	}

	useEffect(()=>{
		getMisApprovals();
	},[])

	const getMisApprovals = async () => {
		try {
			const response = await LenderUgroService.getApprovalsStatus(
				state.userType,
				leadId,
			);
			const approvals = response?.data?.data?.approvals || [];
			setAppprovals(approvals);
		} catch (err) {
			// let error = err?.response?.data?.messages?.error;
		}
	};

	const submitMisApprovals = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			modalTitle && formData.append("type", getApprovalType(modalTitle));
			leadId && formData.append("lead_id", leadId);
			// file && formData.append("file", file);
			// comment && formData.append("comment", comment);
			const response = await LenderUgroService.changeApprovalsStatus(
			state.userType,
			formData
			);

			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
			);
			setModalState(false);
			getMisApprovals();
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};

	return (
        <>
        <div className='d-flex align-items-center' style={{position: 'absolute', top: '140%', right: '20px', zIndex: 99}}>
            <button
                type='button'
                className='btn btn-custom'
                onClick={()=>toggleModal('financial_approval')}
				disabled={approvals?.[1] || leadStatus != 4}
            >
            	Financial Approval {approvals?.[1] && <i class="fa fa-check approvals-check" aria-hidden="true"></i>}
            </button>
			{!(detailLenderId == 21 && prodType == 46) &&
            <button
                type='button'
                className='btn btn-custom ml-2 mr-2'
				onClick={()=>toggleModal('legal_approval')}
				disabled={approvals?.[2] || leadStatus != 4}
            >
            	Legal Approval {approvals?.[2] && <i class="fa fa-check approvals-check" aria-hidden="true"></i>}
            </button>}
			{!(detailLenderId == 21 && prodType == 46) &&
            <button
                type='button'
                className='btn btn-custom'
				onClick={()=>toggleModal('technical_approval')}
				disabled={approvals?.[3] || leadStatus != 4}
            >
            	Technical Approval {approvals?.[3] && <i class="fa fa-check approvals-check" aria-hidden="true"></i>}
            </button>}
        </div>
        <Modal state={modalState} closeModal={()=>setModalState(false)} removeBorderSpace={true}>
			<div className="modal-header-container">
				<div className="modal-title">{splitUnderscoreToCaptialise(modalTitle)}</div>
				<div className="closeModalView" onClick={()=>setModalState(false)}>
				<span className="closeModalIcon">x</span>
				</div>
			</div>
			<div>
				<p className='font-weight-bold text-center mt-2 mb-4'>
					Do you really want to approve?
				</p>
				<div className='d-flex align-items-center justify-content-center mt-2 mb-2'>
					<Button
						clickHandler={()=>setModalState(false)}
						classNames='btn-custom small mr-2'
					>
						No
					</Button>
					<Button clickHandler={submitMisApprovals} classNames='btn-custom small'>
						{loading ? <div className='loader' /> : 'Yes'}
					</Button>
				</div>
			</div>
		</Modal>
        </>
	);
};

export default MISApprovals;
