import React from 'react';
import UserListing from '../components/ManageUser/UserListing';
import {
	getUserList,
	getAffiliateUser,
	getAffiliateUserList,
} from '../services/manage-user';
import Pagination from '../components/Pagination';
import SearchFilter from '../components/ManageUser/SearchFilter';
import { Context } from '../config/store';
import { objectsForEach } from '../common/objectToArray';
import TableHeader from '../components/ManageUser/TableHeader';
import AddAffiliateUser from '../components/ManageUser/AddAffiliateUser';
import { downloadFile } from '../common/download';
import { toast } from 'react-toastify';
import message from '../constant/message';
import UploadUserModal from '../components/ManageUser/UploadUserModal';
import LenderUgroService from '../services/lender';
import './manageUsers.css'
import objectToQueryParams from '../common/objectToQueryParams';
import TableLoading from '../components/Common/Table/TableLoading';
const ManageUsers = ({ type }) => {

	let sessionStorageData = JSON.parse(sessionStorage.getItem('searchData')) || {};
	let { org_page = 1 } = sessionStorageData;
	const queryString = objectToQueryParams(sessionStorageData);

	const [users, setUsers] = React.useState([]);
	const [pagination, setPaginationData] = React.useState({});
	const [page, setPage] = React.useState(org_page);
	const [modalState, toggleModal] = React.useState(false);
	const [queryParams, setQueryParams] = React.useState(queryString);
	const [selectedUserId, setSelectedUserId] = React.useState('');
	const { state } = React.useContext(Context);
	const [exportLoading, setExportLoading] = React.useState(false);
	const [userModalState, setUserModalState] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	// React.useEffect(() => {
	// 	if (state.userType === 'admin' || state.userType === 'lender') getUsers();
	// 	else getAffiliateUsersList();
	// }, [page, queryParams]);

	React.useEffect(() => {
		getUsers(false, false);
	}, [page])

	React.useEffect(() => {
		getUsers(false, true);
	}, [queryParams])
	/**
	 *
	 */
	const getUsers = async (exportList, paramsChanged) => {
		let response;
		setLoading(true);
		if (type === 'lender') {
			response = await LenderUgroService.getUserList({
				type: state.userType,
				page: paramsChanged ? 1 : page,
				queryParams,
				exportList,
			});
			response = response.data;
			setLoading(false);
		} else {
			response = await getUserList({ page, queryParams, exportList });
			setLoading(false);
		}
		if (response?.status?.code === 1) {
			const data = response.data?.resource;
			setUsers(data?.data || []);
			let searchDataNew = { ...sessionStorageData, org_page: (paramsChanged ? 1 : page) }
			sessionStorage.setItem('searchData', JSON.stringify(searchDataNew))
			setPaginationData(data?.pagination || {});
		} else if (exportList && !response?.status) {
			downloadFile(response);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
	};
	/**
	 *
	 */
	const getAffiliateUsersList = async (exportList) => {
		const response = await getAffiliateUserList({
			page,
			queryParams,
			exportList,
		});

		if (response?.status?.code === 1) {
			const data = response.data?.resource;
			setUsers(data?.data || []);
			setPaginationData(data?.pagination || {});
		} else if (exportList && !response?.status) {
			downloadFile(response);
		} else {
			let error = response?.messages?.error;
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR);
		}
	};
	const checkHasPermission = (checkPermission) => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const exportUsers = async () => {
		setExportLoading(true);
		await getUsers(true,false);
		setExportLoading(false);
	};
	const toggleUserModal = () => {
		setUserModalState(!userModalState);
	};
	return (
		<>
			<div>
				{state.userType === 'affiliate' && (
					<a
						href='#'
						className='btn btn-custom btn-custom-height float-right mt-3'
						onClick={(e) => {
							e.preventDefault();
							toggleModal(true);
						}}
					>
						Add User
					</a>
				)}
			</div>
			<div className='clearfix' />
			{state.userType === 'lender' && (
				<SearchFilter
					userType={state.userType}
					setQueryParams={setQueryParams}
					roleList={
						objectsForEach(state?.config?.rolesList, 'STRING_VAL') || []
					}
					businessType={state?.config?.businesstype || []}
				/>
			)}
			{modalState && (
				<AddAffiliateUser
					user_id={selectedUserId}
					state={modalState}
					reloadTable={getAffiliateUsersList}
					toggleModal={toggleModal}
				/>
			)}
			{userModalState && (
				<UploadUserModal
					userType={state.userType}
					state={userModalState}
					toggleModal={toggleUserModal}
					reloadTable={getAffiliateUsersList}
				/>
			)}
			{state.userType === 'admin' && (
				<div className='d-flex justify-content-end mt-4'>
					<button
						onClick={toggleUserModal}
						type='button'
						className='btn btn-custom ml-3'
					>
						{'Add Users'}
					</button>
					<button
						onClick={exportUsers}
						type='button'
						className='btn btn-custom ml-3'
						disabled={exportLoading}
					>
						{exportLoading ? <div className='loader' /> : 'Export Users'}
					</button>
				</div>
			)}
			<div className='manageUsers mt-3'>
				<div className='table-responsive '>
					<table className='table table-striped'>
						<TableHeader type={state.userType} />
						<tbody className='position-relative'>
            			{loading ? <TableLoading/> :
							users.map(({ user_id, ...rest }) => (

								<UserListing
									checkHasPermission={checkHasPermission}
									pageType={type}
									setSelectedUserId={setSelectedUserId}
									toggleModal={toggleModal}
									key={user_id}
									type={state.userType}
									user_id={user_id}
									{...rest}
								/>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<Pagination
				setPage={setPage}
				total_pages={pagination.total_pages}
				total={pagination.total}
			/>
		</>
	);
};

export default ManageUsers;
