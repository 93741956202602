import IFSCCode from '../components/Field/IFSCCode';

export const leadTypes = [
	{ label: 'Cold', value: 1 },
	{ label: 'Warm', value: 2 },
	{ label: 'Hot', value: 3 },
];

export const textState = {
	1: { value: 'text-success', label: 'Cold' },
	2: { value: 'text-warning', label: 'Warm' },
	3: { value: 'text-danger', label: 'Hot' },
};
export const additionalInfoFields = {
	BRE_AdditionalInfo: [
		{ key: 'CRIFScore', type: '' },
		{ key: 'GstMaxConcentration', type: '' },
		{
			key: 'GSTR_Receipt_Operative_Bank',
			type: '',
			label: "Percentage turnover routed from applicant's bank account",
		},
		{ key: 'PropertyOwnership', type: '' },
		{ key: 'PropertyLeaseholdPeriod', type: '' },
		{
			key: 'ResidualLoanTenorProperty',
			type: 'number',
			label: 'Residual life of property at the end of Loan Tenor(Year)',
		},
		{ key: 'Property_Acquiring_Date', type: 'date' },
		{ key: 'LAP_Financing_Date', type: 'date' },
		{
			key: 'BalanceTenorPostLoanTenor',
			type: 'number',
			label: 'Unexpired loan tenor post loan repayment period(Year)',
		},
		{
			key: 'MachineryInvestment',
			type: 'number',
		},
		{ key: 'EquipmentInvestment', type: 'number' },
		{ key: 'SalesTurnover', type: 'number' },
		{ key: 'GrossIncome', type: 'number' },
		{ key: 'BalanceSheetDate1', type: 'date' },
		{ key: 'BalanceSheetTurnover1', type: 'number' },
		{
			key: 'BalanceSheetDate2',
			type: 'date',
		},
		{ key: 'BalanceSheetTurnover2', type: 'number' },
		{ key: 'BalanceSheetDate3', type: 'date' },
		{ key: 'BalanceSheetTurnover3', type: 'number' },
	],
	businessAdditionalInfo: [
		// { key: 'add_info_id', type: '' },
		// { key: 'cl_lead_id', type: '' },

		// {
		// 	key: 'RegistrationNo',
		// 	required: true
		// },
		{
			key: 'BusinessSector',
			required: true,
			options: [
				{ label: 'Manufacturing', value: 'Manufacturing' },
				{ label: 'Service', value: 'Service' },
				{ label: 'Trading', value: 'Trading' },
			],
			type: 'select',
		},
		{
			key: 'BusinessSubSector',
			required: true,
			options: [
				{ label: 'Micro', value: 'Micro' },
				{ label: 'Small', value: 'Small' },
				{ label: 'Medium', value: 'Medium' },
				{ label: 'Other Priority Sector', value: 'Other Priority Sector' },
			],
			type: 'select',
		},
		{
			key: 'PrincipalOS',
			required: true,
		},
		{
			key: 'RegistrationNo',
			required: true,
		},
		{
			key: 'CustomerTaxResidentType',
			required: true,
			options: [
				{ label: 'Resident', value: 'Resident' },
				{ label: 'Non-Resident', value: 'Non-Resident' },
			],
			type: 'select',
		},
		{
			key: 'ResidingSinceDate',
			required: true,
			type: 'date',
		},
		{
			key: 'ModeOfOperation',
			required: true,
			options: [
				{
					value: '1',

					label: 'SELF',
				},
				{
					value: '3',

					label: 'EITHER OR SURVIVOR',
				},
				{
					value: '29',
					label: 'PROPRIETOR',
				},
				{
					value: '31',
					label: 'ANY ONE PARTNER',
				},
				{ value: '33', label: 'ANY TWO PARTNERS' },
				{ label: 'ANY ONE DIRECTOR', value: '39' },
				{ label: 'ANY TWO DIRECTORS', value: '41' },
				{
					label: 'OTHERS',
					value: '81',
				},
			],
			type: 'select',
		},
		{
			key: 'IncorporationCity',
			required: true,
			type: 'select',
			options: [
				{ value: '001', label: 'Hyderabad' },
				{ value: '002', label: 'Bengaluru' },
				{ value: '003', label: 'Hubli' },
				{ value: '004', label: 'Coimbatore' },
				{ value: '005', label: 'Pune' },
				{ value: '006', label: 'Delhi NCR' },
				{ value: '007', label: 'Chandigarh' },
				{ value: '008', label: 'Kanpur' },
				{
					value: '009',

					label: 'Mumbai',
				},
			],
		},
		{
			key: 'BranchLocations',
			required: true,
			type: 'select',
			options: [
				{ value: '001', label: 'Hyderabad' },
				{ value: '002', label: 'Bengaluru' },
				{ value: '003', label: 'Hubli' },
				{ value: '004', label: 'Coimbatore' },
				{ value: '005', label: 'Pune' },
				{ value: '006', label: 'Delhi NCR' },
				{ value: '007', label: 'Chandigarh' },
				{ value: '008', label: 'Kanpur' },
				{
					value: '009',

					label: 'Mumbai',
				},
			],
		},
	],
};
const hideGSTExempt = (body) => {
	return body.is_gst_applicable != 2;
};
const hideGST = (body) => {
	return body.is_gst_applicable != 1;
};
const hideRemark = (body) => !body.is_legal_dispute;
export const partnerDetailsFields = {
	Organisation_Details: [
		{
			key: 'org_name',
			label: 'Organisation Name',
		},
		{ key: 'org_email', label: 'Email', type: 'email', required: true },
		{
			key: 'org_mobile_no',
			type: 'number',
			label: 'Mobile no.',
			required: true,
		},
		{ key: 'org_pan', label: 'Organisation PAN', required: true },
		{ key: 'date_of_incorporation', type: 'date' },
		{
			key: 'udyam_reg_no',
		},
		{
			key: 'biz_const',
			type: 'businessconstitutions',
			label: 'Business Constitution',
			required: true,
		},
		{ key: 'reg_office_addr', label: 'Address', required: true },
		{
			key: 'reg_office_state',
			label: 'State',
			required: true,
			type: 'state',
		},
		{ key: 'reg_office_city', label: 'City', type: 'city', required: true },
		{ key: 'reg_office_zip', type: 'number', label: 'Pincode', required: true },
		{
			key: 'area_of_operation',
			label: 'Area of Operation',
		},
		{
			key: 'years_in_business',
			label: 'No of Years in Current Business',
			required: true,
			type: 'number',
		},
		{
			key: 'is_legal_dispute',
			type: 'legaldispute',
			label: 'Is the applicant involved in any legal dispute?',
		},
		{
			key: 'legal_dispute_remark',
			label: 'Remarks',
			hideField: hideRemark,
		},
	],

	Key_Personnel_Details: {
		multiple: true,
		key: 'promoter_detail',
		fields: [
			{ key: 'name', label: 'Name', required: true },
			{ key: 'email', label: 'Email', type: 'email', required: true },
			{
				key: 'mobile_no',
				type: 'number',
				label: 'Mobile no.',
				required: true,
			},
			{ key: 'pan_number', label: 'PAN', required: true },
			{ key: 'designation', label: 'Designation or Role', required: true },
			{ key: 'date_of_birth', type: 'date', required: true },
			{ key: 'other_address', label: 'Address', required: true },
			{ key: 'other_state', type: 'state', label: 'State', required: true },
			{ key: 'other_city', type: 'city', label: 'City', required: true },
			{ key: 'other_zip', type: 'number', label: 'ZIP', required: true },
		],
	},
	Gst_Details: [
		{
			key: 'is_gst_applicable',
			label: 'Is GST Applicable',
			type: 'select',
			options: [
				{ label: 'Not Applicable', value: '0' },
				{ label: 'Applicable', value: '1' },
				{ label: 'Exempted', value: '2' },
			],
			required: true,
		},
		{ key: 'gst', label: 'GST', required: true, hideField: hideGST },
		{
			key: 'gst_exem_not_no',
			label: 'GST Exemption Notification Number',
			required: true,
			hideField: hideGSTExempt,
		},
	],
};
export const bankDetailsFields = {
	Bank_Details: [
		{ key: 'ifsc', label: 'IFSC Code', required: true, component: IFSCCode },
		{ key: 'bank_name', required: true },
		{ key: 'branch_name', label: 'Branch', required: true },
		{ key: 'branch_addr', label: 'Address', required: true },
		{ key: 'account_name', label: 'Account Holder Name', required: true },
		{ key: 'account_number', label: 'Account No.', required: true },
		{
			key: 'cheque_file_id',
			label: 'Cheque ( PDF, JPG, PNG and TEXT file are viewable )',
			required: false,
			type: 'cheque',
		},
	],
};
export const inactiveTime = 30; // time is in Minutes

export const securedIds = [1,3,5,9,10,11,19,21,22,24,26,32,34,36,37,38,39,45,46,48,49,50,51,52,56,58,59,61,67,71,85,87,88,89,90,91,92,93,94,98,99,102,106,107,108,109,110,111,113,114,116,117,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,163,164,165,166,167,168,169];
