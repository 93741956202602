import React, { useState } from 'react';
import AssignLenderLeadModal from '../../../components/Portfolio/AssignLenderLeadModal';
import Button from '../../../components/Common/Button';
import AddQueryModal from '../../../components/Portfolio/AddQueryModal';
import Modal from '../../../components/Common/Modal';
import { useLocation, useNavigate, useParams } from 'react-router';
import LenderUgroService from '../../../services/lender';
import { toast } from 'react-toastify';
import message from '../../../constant/message';
import MasterDataService from '../../../services/master-data';
import FinalSubmitToLender from './FinalSubmitToLender';
import SubmitToLender from './SubmitToLender';
import PullBackModal from './PullBackModal';
import { securedIds } from '../../../constant/constants';
import { objectsForEach } from '../../../common/objectToArray';
import OpsModal from './OpsModal';
import RejectModal from './RejectModal';
import ApproveModal from './ApproveModal';
import MISApprovals from './MISApprovals';

const PortfolioActions = ({
	state,
	updateLeadDetail,
	detailsResponse,
	detailLenderId,
	detailLenderName,
}) => {
	const location = useLocation()
	const [assignModalState, setAssignModalState] = React.useState(false);
	const [showRejectModal, setShowRejectModal] = React.useState(false);
	const [showApproveModal, setShowApproveModal] = React.useState(false);
	const [showPullBackConfirm, setShowPullBackConfirm] = React.useState(false);

	const [queryOptions, setQueryOptions] = React.useState([]);
	const [opsActionType, setOpsActionType] = React.useState();
	const [route, setRoute] = useState()
	const [detailresponse, setDetailsResponse] = React.useState();
	const [showQueryButtons, setShowQueryButtons] = React.useState(false);
	const [detailInfo, setDetailInfo] = React.useState(null);
	const [showFinalSubmitModal, setShowFinalSubmitModal] = React.useState(false);
	const [showFinalSubmitButton, setshowFinalSubmitButton] =
		React.useState(false);
	const [showSubmitToLenderModal, setShowSubmitToLenderModal] =
		React.useState(false);
	const [modalState, setModalState] = React.useState(false);
	const [showOpsModal, setOpsModalState] = React.useState(false);

	const navigate = useNavigate();
	const leadId = useParams()['id'];

	const toggleAssignModal = () => {
		setAssignModalState(!assignModalState);
	};
	const toggleRejectModal = () => {
		setShowRejectModal(!showRejectModal);
	};
	const toggleApproveModal = () => {
		setShowApproveModal(!showApproveModal);
	};
	const toggleFinalSubmitModal = () => {
		setShowFinalSubmitModal(!showFinalSubmitModal);
	};

	const toggleSubmitToLenderModal = () => {
		setShowSubmitToLenderModal(!showSubmitToLenderModal);
	};

	const toggleOpsModal = (type, routes) => {
		setRoute(routes)
		setOpsActionType(type);
		setOpsModalState(!showOpsModal);
	};
	const togglePullBackModal = () => {
		setShowPullBackConfirm(!showPullBackConfirm);
	};
	const getQueryOptions = async () => {
		const response = await MasterDataService.getProductList(state.userType, '');
		if (response?.data?.status?.code === 1) {
			setQueryOptions(response.data.data.query_type);
		}
	};
	React.useEffect(() => {
		getPreapprovedLenders();
	}, []);
	const getPreapprovedLenders = async () => {
		try {
			const response = await MasterDataService.getPreapprovedLenders(
				state.userType,
				'co-lender',
				leadId
			);

			let responseList = objectsForEach(
				response.data.data.lender_list,
				'STRING_VAL'
			);
			let lenderItems = responseList.map((response) => ({
				label: response.value,
				value: response.id,
			}));

			if (lenderItems.length === 0) {
			} else {
				setshowFinalSubmitButton(true);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const toggleModal = () => {
		setModalState(!modalState);
	};

	const getLeadDetail = async (assignCallback) => {
		const response = await LenderUgroService.getPortfolioDetail({
			type: state.userType,
			leadId,
			lenderId: detailLenderId,
		});

		setDetailsResponse(response?.data?.data?.details);
		setShowQueryButtons(
			response?.data?.data?.details?.cl_lead_lender_status === 2 ||
			response?.data?.data?.details?.cl_lead_lender_status === 5
		);
		if (assignCallback) {
			updateLeadDetail(response?.data?.data?.details);
			redirectToAdditional(response?.data?.data?.details.cl_lead_id);
		}
	};

	const checkHasPermission = (checkPermission) => {
		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};

	const redirectToAssigned = () => navigate('/portfolio/approved');
	const redirectToRejected = () => navigate('/portfolio/sanction-rejected');
	const redirectToAdditional = (leadId) => {
		navigate('/portfolio/view/' + leadId + '/additional');
	};
	const getLeadDetailInfo = async () => {
		const response = await LenderUgroService.getLeadDetailInfo({
			type: state.userType,
			leadId: leadId,
		});
		setDetailInfo(response?.data?.data?.details);
	};
	React.useLayoutEffect(() => {
		getLeadDetail();

		if (state.userType !== 'admin') {
			getQueryOptions();
		}
		console.log(state.userType);
		if (state.userType == 'lender') {
			getLeadDetailInfo();
		}
	}, []);
	return (
		<>
			{Boolean(state.userType === 'admin') && (
				<div class='col-3'>
					<div className='d-flex justify-content-end'>
						{Boolean(
							state.userType === 'admin' &&
							checkHasPermission('assign-colender-lead') &&
							detailsResponse?.cl_lead_lender_status === 1
						) && (
								<button
									onClick={toggleAssignModal}
									class='btn btn-custom'
									type='button'
									data-toggle='modal'
									data-target='#exampleModalCenter'
								>
									Assign Lender
								</button>
							)}

						{((detailsResponse?.cl_lead_lender_status == 6 &&
							detailsResponse?.cl_lead_for_disbursal == true) ||
							(detailsResponse?.cl_lead_lender_status !== 6 &&
								detailsResponse?.cl_lead_lender_status !== 8)) &&
							checkHasPermission('pulled-back-application') && (
								<button
									onClick={togglePullBackModal}
									class='btn btn-custom'
									type='button'
									data-toggle='modal'
									data-target='#exampleModalCenter'
								>
									Pull Back
								</button>
							)}

						{checkHasPermission('submit-to-lender') &&
							state.userType === 'admin' &&
							detailsResponse?.cl_lead_lender_status === 3 && (
								<button
									onClick={toggleSubmitToLenderModal}
									class='btn btn-custom ml-3'
									type='button'
									data-toggle='modal'
									data-target='#exampleModalCenter'
								>
									Submit to lender
								</button>
							)}
						{checkHasPermission('final-submit-to-lender') &&
							state.userType === 'admin' &&
							showFinalSubmitButton &&
							detailsResponse?.cl_lead_lender_status !== 6 && (
								<button
									onClick={toggleFinalSubmitModal}
									className='btn btn-custom ml-3'
									type='button'
									data-toggle='modal'
									data-target='#exampleModalCenter'
								>
									Final Submit to Lender
								</button>
							)}
					</div>
					{showPullBackConfirm && (
						<PullBackModal
							modelState={showPullBackConfirm}
							toggleModal={togglePullBackModal}
							getLeadDetail={getLeadDetail}
							toggleRejectModal={toggleRejectModal}
							leadType={detailsResponse?.cl_lead?.lead_type || ''}
						/>
					)}
				</div>
			)}

			{(state.userType === 'lender' && checkHasPermission('disburse-reject') && 
				detailsResponse?.cl_lead_lender_status == 9) &&
				(
					<div class='col-3'>
						<div className='d-flex justify-content-end'>
							<button
								onClick={() => toggleOpsModal('approve', 'disbursal')}
								class='btn btn-custom ml-2'
								type='button'
							>
								{['21'].includes(detailLenderId) ? 'Disburse' : 'Approve'}
							</button>
							<button
								onClick={() => toggleOpsModal('reject', 'disbursal')}
								class='btn btn-custom ml-2'
								type='button'
							>
								Reject
							</button>
						</div>
					</div>
				)}

			{(state.userType === 'lender' && checkHasPermission('disburse-reject') && 
				detailsResponse?.cl_lead_lender_status === 10) && 
				(
					<div class='col-3'>
						<div className='d-flex justify-content-end'>
							<button
								onClick={() => toggleOpsModal('disburse', 'disbursal')}
								class='btn btn-custom ml-2'
								type='button'
							>
								Disburse 
								{/*  this is prnding for ststus  */}
							</button>

						</div>
					</div>
				)}

			{showOpsModal && opsActionType && (
				<OpsModal
					state={showOpsModal}
					actionType={opsActionType}
					toggleModal={toggleOpsModal}
					leadId={leadId}
					userType={state.userType}
					route={route}
				/>
			)}
			{showFinalSubmitModal && (
				<FinalSubmitToLender
					modelState={showFinalSubmitModal}
					toggleModal={toggleFinalSubmitModal}
					detailLenderName={detailLenderName}
				/>
			)}
			{showSubmitToLenderModal && (
				<SubmitToLender
					modelState={showSubmitToLenderModal}
					toggleModal={toggleSubmitToLenderModal}
					detailLenderId={detailLenderId}
					detailLenderName={detailLenderName}
					getLeadDetail={getLeadDetail}
				/>
			)}
			{state.userType === 'lender' && ['8','21'].includes(detailLenderId) && 
				[4,5,6,7,9,10,11,15].includes(detailsResponse?.cl_lead_lender_status) && 
				securedIds.includes(detailsResponse?.cl_lead?.prod_type) && 
				detailsResponse?.cl_lead?.lead_type == 1 &&
				(
					<MISApprovals detailLenderId={detailLenderId} leadId={leadId} detailsResponse={detailsResponse} />
				)}
			{state.userType === 'lender' &&
				checkHasPermission('sanction-reject') &&
				detailsResponse &&
				detailsResponse?.cl_lead_lender_status == 4 ||
				detailsResponse?.cl_lead_lender_status == 17 
				? (
					<>
						<div className='d-flex align-items-center'>
							<Button
								type='btn-custom'
								classNames='small'
								clickHandler={() => setShowApproveModal(true)}
							>
								Sanction
							</Button>
							<Button
								clickHandler={() => setShowRejectModal(true)}
								classNames='btn-custom small ml-2 mr-2'
							>
								Reject
							</Button>
							{/* {showQueryButtons && (
								<Button
									clickHandler={toggleModal}
									classNames='btn-outline-custom small text-orange mr-2'
								>
									Query
								</Button>
							)} */}
						</div>

						{modalState && (
							<AddQueryModal
								userType={state.userType}
								state={modalState}
								toggleModal={toggleModal}
								queryOptions={queryOptions}
								leadId={leadId}
								globalState={state}
							/>
						)}

						{showRejectModal && (
							<RejectModal
								showRejectModal={showRejectModal}
								leadId={leadId}
								userType={state.userType}
								toggleRejectModal={toggleRejectModal}
								redirectToAssigned={redirectToRejected}
							/>
						)}

						{showApproveModal && (
							<ApproveModal
								leadId={leadId}
								userType={state.userType}
								showApproveModal={showApproveModal}
								toggleApproveModal={toggleApproveModal}
								redirectToAssigned={redirectToAssigned}
							/>
						)}
					</>
				) : (
					''
				)}
			{state.userType === 'lender' &&
				checkHasPermission('sanction-reject') &&
				detailsResponse &&
				detailsResponse?.cl_lead_lender_status == 12
				? (

					<div class='col-3'>
						<div className='d-flex justify-content-end'>
							<button
								onClick={() => toggleOpsModal('approve', 'pre-sanction')}
								class='btn btn-custom ml-2 small'
								type='button'
							>
								Pre-sanction approve
							</button>
							<button
								onClick={() => toggleOpsModal('reject', 'pre-sanction')}
								class='btn btn-custom ml-2 small'
								type='button'
							>
								Pre-sanction reject
							</button>
						</div>
					</div>
				) : (
					''
				)}
			{state.userType !== 'lender' && assignModalState ? (
				<AssignLenderLeadModal
					modelState={assignModalState}
					toggleModal={toggleAssignModal}
					reloadTable={() => getLeadDetail(true)}
					colenders={[]}
					selectedIds={[leadId]}
					leadType={detailsResponse?.cl_lead?.lead_type || ''}
					type='details'
				/>
			) : (
				''
			)}
		</>
	);
};

export default PortfolioActions;
