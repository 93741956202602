import React, { useState } from 'react';
import '../../../css/custom-accordion.css';
import { useParams, useLocation } from 'react-router';
import LenderUgroService from '../../../services/lender';
import MasterDataService from '../../../services/master-data';
import { Context } from '../../../config/store';
import Accordion from '../../../components/Portfolio/Accordion';
import Table from '../../../components/Common/Table';
import message from '../../../constant/message';
import { toast } from 'react-toastify';
import { objectsForEach } from '../../../common/objectToArray';
import { getFormattedDate } from '../../../common/date';
import DocumentDownload from './DocumentDownload';
import DocumentUploadField from './DocumentUploadField';
import './Documents.css'

const Documents = ({
	cl_lead_lender_status,
	selectedLender,
	selectedLenderName,
	getLeadDetail,
	isPostDisbursalCase = false
}) => {
	let inputRef = React.useRef(null);
	const documentFileTableHeaders = [
		{
			title: 'File Name',
			field: 'file_name',
		},
		{
			title: 'Uploaded On',
			field: 'created_at',
		},
		{
			title: 'Action',
			field: 'action',
			component: DocumentDownload,
		},
	];
	const defaultState = {
		document_types: {
			type: 'select',
			value: '',
			dataType: 'array',
		},
	};
	const bankFields = {
		bank_types: {
			type: 'select',
			value: '',
			label: 'Bank Name',
		},
		document_password: {
			type: 'text',
			value: ' ',
		},
	};
	const bankStatementFields = {
		account_holder_name: {
			type: 'text',
			value: ' ',
		},
		account_type: {
			type: 'select',
			value: ' ',
			label: 'Account Type',
		},
		// ODCC: {
		// 	type: 'text',
		// 	value: ' ',
		// 	label: 'ODCC Limit',
		// },
		ifsc: {
			type: 'text',
			value: ' ',
			label: 'IFSC',
		},

		account_number: {
			type: 'text',
			value: ' ',
		},
	};
	const reportFields = {
		report_type: {
			type: 'select',
			value: ' ',
			label: 'Report Type',
		},
		pan_no: {
			type: 'text',
			value: ' ',
		},
	};
	const additionalFields = {
		RefNumber: {
			type: 'text',
			value: '',
		},
		IssueDate: {
			type: 'date',
			value: '',
		},
		DocIssuingAuthority: {
			type: 'select',
			value: '',
		},
		PlaceOfIssue: {
			type: 'select',
			value: '',
		},
		PreferredFlag: {
			type: 'select',
			value: '',
		},
	};
	const location = useLocation()
	const { state } = React.useContext(Context);
	const leadId = useParams().id;
	const [documentList, setDocumentList] = React.useState({});
	const [uploadDocInfo, setUploadDocInfo] = React.useState(defaultState);
	const [fetchingDocuments, setFetchingDocuments] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [file, setFile] = React.useState(null);
	const [documentData, setDocomentData] = React.useState();
	const [errors, setErrors] = React.useState({});
	const [getDocumentError, setGetDocumentError] = React.useState(false);
	const [getDocumentErrorMessage, setGetDocumentErrorMessage] =
		React.useState('');
	const [allSelected, setAllSelected] = React.useState(false);
	const [hasSoaDocuments, setHasSoaDocuments] = React.useState(false);
	const [docloader, setDocloader] = useState(false)
	const [docKeys, setDocKeys] = useState([])
	const [selectedDocKey, setSelectedDocKey] = useState([])
	const [selectedDocValue, setSelectedDocValue] = useState([])
	const [activeDoc, setActiveDoc] = useState("")
	const [docCount, setDocCount] = useState(0)

	const viewableSelectedLender = (row) => {
		return (
			typeof row?.for_lenders === 'string'
				? JSON.parse(row.for_lenders)
				: row?.for_lenders || []
		).some((lender) => lender === selectedLender);
	};
	const getLeadDocument = async (isDefault) => {
		setDocloader(true)
		setGetDocumentError(false);
		setGetDocumentErrorMessage('');
		try {
			const response = await LenderUgroService.getPortfolioDocuments({
				type: state.userType,
				leadId: leadId,
				lenderId: allSelected ? 0 : selectedLender,
			});

			let docData = objectsForEach(response.data.data.lead_documents)
			let keys = [];
			let values = [];

			docData?.map(({ id, value }) => {
				if (value[0]?.document_file?.length > 0) {
					let docName = id;
					let docCount = value[0]?.document_file?.length;
					let leftData = {
						docName,
						docCount,
						value
					}
					keys.push(leftData)
					values.push(value)
				}
				if ((id === activeDoc) && !isDefault) {
					setSelectedDocValue(value)
				}
			})

			isDefault && !isPostDisbursalCase && setActiveDoc(keys[0]?.docName)
			isDefault && !isPostDisbursalCase && setSelectedDocValue(keys[0]?.value)
			isDefault && !isPostDisbursalCase && setDocCount(keys[0]?.value[0]?.document_file?.length)
			if (isPostDisbursalCase) {
				keys?.map(({ docName, docCount, value }) => {
					if (docName === "Statement of Account") {
						setDocKeys([{
							docName,
							docCount,
							value
						}])
						setActiveDoc(docName)
						setDocCount(docCount)
						setSelectedDocValue(value)
					}
				})
			} else {
				setDocKeys(keys)
			}

			// let data = Object.entries(response.data.data.lead_documents);
			// data?.map((item)=>{
			// 	if (item[0] === "Statement of Account") {
			// 		setHasSoaDocuments(true)
			// 	}
			// })
			setDocumentList(response.data.data.lead_documents);
			setDocloader(false)
		} catch (err) {
			let error = err?.response?.messages?.error;
			setGetDocumentError(true);
			setGetDocumentErrorMessage(
				error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM
			);
			setDocloader(false)
		}
	};

	const getUploadDocumentData = async () => {
		const response = await MasterDataService.getDocumentList(
			state.userType,
			'co-lender',
			leadId,
			selectedLender
		);

		setDocomentData(response.data.data);
	};

	const submitUploadDocument = async (e) => {
		e.preventDefault();
		if (!file) {
			return toast.error(message.SELECT_FILE);
		}
		setLoading(true);
		var formData = new FormData();
		Object.keys(uploadDocInfo).forEach((key) => {
			if (key === 'document_types') {
				formData.append(
					'doc_type',
					documentData.document_types[uploadDocInfo[key].value].id
				);
			} else if (key === 'IssueDate') {
				formData.append(
					'IssueDate',
					getFormattedDate(new Date(uploadDocInfo[key].value))
				);
			} else if (key === 'bank_types') {
				formData.append('bank_id', uploadDocInfo[key].value);
			} else if (key === 'document_password') {
				formData.append('password', uploadDocInfo[key].value);
			} else {
				formData.append(key, uploadDocInfo[key].value);
			}
		});
		formData.append('file', file);
		try {
			const response = await MasterDataService.uploadDocument(
				state.userType,
				'co-lender',
				leadId,
				formData,
				selectedLender
			);
			if (response?.data?.status?.code === 1) {
				let success = response?.messages?.success;
				toast.success(
					success?.form?.[0] || success?.others?.[0] || message.UPLOAD_SUCCESS
				);
				inputRef.current.value = '';
				setFile(null);
				setUploadDocInfo(defaultState);
				getLeadDocument(false);
				getLeadDetail();
			} else {
				let error = response.messages.error;

				toast.error(
					error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM
				);
			}
		} catch (err) {
			let error = err?.response?.data.messages?.error;

			setErrors(error);
			toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
		}
		setLoading(false);
	};
	const documentFieldChangeHandler = (value, key) => {
		if (key === 'document_types') {
			let currentDocument = documentData.document_types[value];
			if (
				currentDocument?.document_is_doc_info === 1 &&
				currentDocument?.lender_is_doc_info === 1
			) {
				return setUploadDocInfo({
					[key]: {
						...uploadDocInfo[key],
						value: value,
					},
					...additionalFields,
					...(currentDocument?.protected_file === 1 && { ...bankFields }),
					...(currentDocument?.is_bank_info === 1 && {
						...bankStatementFields,
					}),
					...(currentDocument?.is_report_info === 1 && {
						...reportFields,
					}),
				});
			} else {
				return setUploadDocInfo({
					[key]: {
						...uploadDocInfo[key],
						value: value,
					},
					...(currentDocument?.is_bank_info === 1 && {
						...bankStatementFields,
					}),
					...(currentDocument?.protected_file === 1 && { ...bankFields }),
					...(currentDocument?.is_report_info === 1 && {
						...reportFields,
					}),
				});
			}
		}
		if (key === "account_type") {
			if (value === "OverDraft") {
				let temp = {
					...uploadDocInfo,
					[key]: {
						...uploadDocInfo[key],
						value: value,
					}
				}
				setUploadDocInfo({ ...temp, odcc_limit: { type: "text", value: null, label: "ODCC Limit" } })
			} else {
				let temp = {
					...uploadDocInfo,
					[key]: {
						...uploadDocInfo[key],
						value: value,
					}
				};
				delete temp.odcc_limit;
				setUploadDocInfo(temp)
			}
		} else {

			setUploadDocInfo({
				...uploadDocInfo,
				[key]: {
					...uploadDocInfo[key],
					value: value,
				},
			});
		}
	};

	const checkUploadButtonDisabled = () => {
		var allDocSelect = true;
		for (var key in uploadDocInfo) {
			if (!uploadDocInfo[key].value && key !== "odcc_limit") {
				allDocSelect = false;
			}
		}

		return !file || !allDocSelect || loading;
	};
	const fetchDmsDocuments = async () => {
		setFetchingDocuments(true);
		try {
			const response = await LenderUgroService.fetchDocuments(leadId);
			if (response?.data?.status?.code === 1) {
				getLeadDocument(false);
				toast.success(
					response?.data?.messages?.success?.others[0] ||
					'DMS documents fetched successfully'
				);
			} else {
				toast.error(
					response?.message ||
					response?.messages?.error?.form?.[0] ||
					response?.messages?.error?.others?.[0] ||
					message.ERROR
				);
			}
		} catch (err) {
			toast.error(
				err.response?.data?.message ||
				err.response?.data?.messages?.error?.form[0] ||
				err.response?.data?.messages?.error?.others[0] ||
				message.ERROR
			);
		}
		setFetchingDocuments(false);
	};

	React.useLayoutEffect(() => {
		getLeadDocument(true);
	}, [selectedLender, allSelected]);
	const setDefaultState = () => {
		setUploadDocInfo(defaultState);
	};
	React.useLayoutEffect(() => {
		if (state.userType === 'admin' && selectedLender) {
			setDefaultState();
			getUploadDocumentData();
		}
	}, [selectedLender]);

	const formatRows = (documents) => {
		return documents.document_file.map((document) => ({
			...document,
			checked: viewableSelectedLender(document),
		}));
	};
	const checkHasPermission = (checkPermission) => {

		if (state.is_super_admin === 1) return true;
		const permission = state.permission.find(
			(permission) => permission === checkPermission
		);

		if (permission) return true;
		else return false;
	};
	const toggleDocumentVisibilityForLender = async (
		tableData,
		selectedRowCount,
		checked,
		index
	) => {
		const row = tableData[index];

		try {
			const response = await LenderUgroService.toggleDocumentForLender(
				state.userType,
				state.userType === 'admin' ? 'co-lender' : null,
				{
					doc_lender_updates: [{ [row.lead_doc_file_id]: row.checked ? 1 : 0 }],
					lender_id: selectedLender,
				}
			);
			const success = response?.data?.messages?.success;
			toast.success(
				success?.form?.[0] || success?.others?.[0] || message.UPDATE_SUCCESS
			);
			getLeadDocument(false);
		} catch (err) {
			let error = err?.response?.data?.messages?.error;
			toast.error(
				error?.fields?.doc_password?.[0] ||
				error?.form?.[0] ||
				error?.others?.[0] ||
				message.ERROR_FORM
			);
		}
	};

	const getSelectedDocument = (docName) => {
		objectsForEach(documentList)?.map(({ id, value }) => {
			if (id == docName) {
				setActiveDoc(docName)
				setDocCount(value[0]?.document_file?.length)
				setSelectedDocValue(value)
			}
		})
	}

	return (
		<>
			{state?.userType === 'lender' && state?.user?.cl_lender_id === 8 && (
							<div className='d-flex justify-content-end' style={{ marginTop: "-8px" }}>
								<button
									className='btn btn-custom btn-custom-height newButton mr-1'
									onClick={fetchDmsDocuments}
								>
									{fetchingDocuments ? (
										<div className='loader' />
									) : (
										<span className=''><i class="fa fa-refresh mr-1" aria-hidden="true"></i>Fetch Documents</span>
									)}
								</button>
							</div>
			)}

			<div className='mt-1'>
				{state.userType === 'admin' && (
					<div className='custom_radio_control mr-4'>
						<input
							type='checkbox'
							onChange={(e) => setAllSelected(e.target.checked)}
							checked={allSelected}
						/>
						<label className='ml-1'>Show all Documents</label>
					</div>
				)}
			</div>
			{docloader ? <div className='Tableloader' style={{ margin: "10% auto auto auto" }}></div>
				:
				((selectedDocValue || []).length) === 0 ?
				<div className="d-flex justify-content-center" style={{marginTop: '100px',fontSize: '16px'}}>{isPostDisbursalCase ? 'No SOA Documents Found' : 'No Documents Found'}</div>
				:
				<>
					<div className="row view-container mt-2">
						<div className="col-md-3">
							<div className="documentLeftPart">
								{docKeys?.map(({ docName, docCount, docValue }) => {
									return (
										<p
											className={`d-flex justify-content-between ${activeDoc === docName && 'activeDocument'}`}
											onClick={() => getSelectedDocument(docName)}
										>
											<div style={{ cursor: 'pointer' }}>
												{`(${docCount}) ${docName}`}
											</div>
											{activeDoc === docName && <span><i class="fa fa-angle-right fa-lg" aria-hidden="true"></i></span>}
										</p>
									)
								})
								}
							</div>
						</div>
						<div className="col-md-9">
							<div className="documentRightPart">
								<span className='docLegend'>{`${docCount} docs uploaded`}</span>
								<p className='docHeader'>{activeDoc}</p>
								{
									(selectedDocValue || [])?.map((document) => {
										if (isPostDisbursalCase) {
											if (activeDoc === "Statement of Account") {
												return (
													<div className='docTableData'>
														{document?.document_file &&
															document.document_file?.length > 0 ? (
															<div className='document_table'>
																<Table
																	rowCheckboxUpdateCallback={
																		toggleDocumentVisibilityForLender
																	}
																	columns={documentFileTableHeaders}
																	rows={formatRows(document)}
																	reloadTable={() => getLeadDocument(false)}
																	// showHeaderCheckbox={false}
																	// checkbox={true}
																	type='documents'
																	selectedLender={selectedLender}
																	selectedLenderName={selectedLenderName}
																	documentName={activeDoc}
																	documentValue={document}
																	// isDocumentSubmitting={isDocumentSubmitting}
																></Table>
															</div>)
															:
															"No Documents found"
														}
													</div>
												)
											}
										} else {
											return (
												<div className='docTableData'>
													{document?.document_file &&
														document.document_file?.length > 0 ? (
														<div className='document_table'>
															<Table
																rowCheckboxUpdateCallback={
																	toggleDocumentVisibilityForLender
																}
																columns={documentFileTableHeaders}
																rows={formatRows(document)}
																reloadTable={() => getLeadDocument(false)}
																// showHeaderCheckbox={false}
																// checkbox={true}
																type='documents'
																selectedLender={selectedLender}
																selectedLenderName={selectedLenderName}
																documentName={activeDoc}
																documentValue={document}
																// isDocumentSubmitting={isDocumentSubmitting}
															></Table>
														</div>)
														:
														"No Documents found"
													}
												</div>
											)
										}
									})

								}
							</div>
						</div>
					</div>
				</>
			}
			{/* {docloader ? <div className='Tableloader' style={{ margin: "10% auto auto auto" }}></div> : <div className='row mt-3'> */}
				{/* <div className='col-md-12'>
					<div className='card border-0'>
						<div className='card-body p-0'>
							{!getDocumentError ? (
								objectsForEach(documentList)?.length > 0 ?
									<div id='accordion' className='custom_accordion'>
										{objectsForEach(documentList).map((document, i) => {
											if (isPostDisbursalCase) {
												if (document?.id === "Statement of Account") {
													return (
														<Accordion
															key={i}
															label={
																document.id ||
																(document.value[0].doc_name ==
																	'Technical Report Generated by Ugro'
																	? 'FI Report'
																	: document.value[0].doc_name)
															}
															required={
																!document.value[0]?.is_uplod &&
																cl_lead_lender_status &&
																cl_lead_lender_status.lender_id === 3 &&
																document.value[0]?.is_required > 0
															}
															count={document.value[0]?.document_file?.length}
															headerId={'heading' + i}
															collapseId={'collapse' + i}
															isDocumentAccordion={true}
															isPostDisbursalCase={true}
														>
															{document.value[0]?.document_file &&
																document.value[0].document_file?.length > 0 ? (
																<div className='post-disbursal-document_table'>
																	<Table
																		columns={documentFileTableHeaders}
																		rows={formatRows(document)}
																		reloadTable={getLeadDocument}
																		type='documents'
																		selectedLender={selectedLender}
																		selectedLenderName={selectedLenderName}
																		documentList={document}
																	></Table>
																</div>
															) : (
																<span className="d-flex justify-content-center m-2"><h6>No Documents Available</h6></span>
															)}
														</Accordion>
													);
												}
											} else {
												return (
													<Accordion
														key={i}
														label={
															document.id ||
															(document.value[0].doc_name ==
																'Technical Report Generated by Ugro'
																? 'FI Report'
																: document.value[0].doc_name)
														}
														required={
															!document.value[0]?.is_uplod &&
															cl_lead_lender_status &&
															cl_lead_lender_status.lender_id === 3 &&
															document.value[0]?.is_required > 0
														}
														count={document.value[0]?.document_file?.length}
														headerId={'heading' + i}
														collapseId={'collapse' + i}
														isDocumentAccordion={true}
													>
														{document.value[0]?.document_file &&
															document.value[0].document_file?.length > 0 ? (
															<div className='documentsTable'>
																<Table
																	rowCheckboxUpdateCallback={
																		toggleDocumentVisibilityForLender
																	}
																	columns={documentFileTableHeaders}
																	rows={formatRows(document)}
																	reloadTable={getLeadDocument}
																	showHeaderCheckbox={false}
																	// checkbox={true}
																	type='documents'
																	selectedLender={selectedLender}
																	selectedLenderName={selectedLenderName}
																	documentList={document}
																></Table>
															</div>
														) : (
															<span className="d-flex justify-content-center m-2"><h6>No Documents Available</h6></span>
														)}
													</Accordion>
												);
											}
										})
										}
									</div>
									:
									isPostDisbursalCase ? <div className='text-center col-12' style={{ fontSize: 16, marginTop: '35vh' }}>No SOA Documents Found</div> : ''
							) : (
								<p>{getDocumentErrorMessage}</p>
							)}
							{!hasSoaDocuments && objectsForEach(documentList)?.length > 0 && <div className='text-center col-12' style={{ fontSize: 16, marginTop: '35vh' }}>No SOA Documents Found</div>}
						</div>
					</div>
				</div> */}
				{/* {state?.userType === 'lender' && state?.user?.cl_lender_id === 8 && (
					<div className='col-md-5'>
						{(
							<div className='d-flex justify-content-end'>
								<button
									className='btn btn-custom btn-custom-height newButton'
									onClick={fetchDmsDocuments}
								>
									{fetchingDocuments ? (
										<div className='loader' />
									) : (
										'Fetch Documents'
									)}
								</button>
							</div>
						)} */}
						{/* <div className='card border-0'>
							<div className='card-body custom'>
								<h6>
									<small>
										<b>Upload More Documents</b>
									</small>
								</h6>

								<form onSubmit={submitUploadDocument}>
									{objectsForEach(uploadDocInfo).map(({ id, label }) => (
										<DocumentUploadField
											key={id}
											id={id}
											label={label}
											inputType={uploadDocInfo[id].type}
											required={documentData?.doc_info_required}
											onChange={documentFieldChangeHandler}
											dataType={uploadDocInfo?.[id]?.dataType}
											value={uploadDocInfo[id].value}
											options={documentData?.[id]}
											error={errors?.fields?.[id]}
										/>
									))}

									<div className='form-group'>
										<label>Upload Document</label>
										<div className='row'>
											<div className='col-lg-9 col-md-8 col-sm-9 col-8'>
												<div className='form-group mt-3'>
													<input
														ref={inputRef}
														file={file}
														onChange={(e) => setFile(e.target.files[0])}
														type='file'
														className='form-control'
													/>
												</div>
											</div>
											<div className='col-lg-3 col-md-4 col-sm-3 col-4 d-flex align-items-center justify-content-end'>
												{(state.userType === 'admin'
													? checkHasPermission('upload-documents')
													: true) && (
														<button
															className='btn btn-custom'
															disabled={checkUploadButtonDisabled()}
														>
															{loading ? (
																<div className='loader'></div>
															) : (
																'Upload'
															)}
														</button>
													)}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div> */}
					{/* </div> */}
				{/* )} */}
			{/* </div>} */}
		</>
	);
};

export default Documents;

