import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { objectsForEach } from "../../../common/objectToArray";
import Select from "../../../components/Common/Input/Select";
import Error from "../../../components/Error";
import Modal from "../../../components/Modal";
import message from "../../../constant/message";
import LenderUgroService from "../../../services/lender";
import MasterDataService from "../../../services/master-data";

export default function OpsModal({
  toggleModal,
  leadId,
  userType,
  state,
  reloadTable,
  actionType,
  route
}) {

  let inputRef = React.useRef(null);
  const navigate = useNavigate();
  const [file, setFile] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [type, setOpsModalActionType] = React.useState(actionType || "approve");
  const [rejectType, setRejectType] = React.useState(null);
  const [reason, setReason] = React.useState("")
  const [reasonslist, setReasonsList] = useState([])
  const [reasons_name, setselectedReasons_name] = React.useState()
  console.log(route, "route", actionType, "action type ")



  const [rejectOptions] = React.useState([
    { label: "Business Vintage", value: "1" },
    { label: "CIBIL,DPD & DSCR", value: "2" },
    { label: "Eligibility Method", value: "3" },
    { label: "Technical", value: "4" },
    { label: "Legal", value: "5" },
    { label: "LTV", value: "6" },
    { label: "Property", value: "7" },
  ]);
  const [errors, setErrors] = React.useState(null);

  const getreasonslist = async () => {
    const response = await MasterDataService.getReasonsList(
      state.userType,
      'co-lender',
      type
    );

    let responseList = objectsForEach(
      response.data.data.reasons_list,
      'STRING_VAL'
    );
    let ReasonsList_name = responseList.map((response) => ({
      label: response.value,
      value: response.id,
    }));
    setReasonsList(ReasonsList_name);
  };

  React.useEffect(() => {
    getreasonslist();
  }, []);
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    await modifyLeadOpsStatus();
    reloadTable && reloadTable();
    setLoading(false);
  };

  const modifyLeadOpsStatus = async () => {
    try {
      const formData = new FormData();
      type && formData.append("type", type);
      reasons_name && formData.append("reasons_id", reasons_name);
      leadId && formData.append("lead_id", leadId);
      file && formData.append("file", file);
      comment && formData.append("comment", comment);
      rejectType && formData.append("reject_type", rejectType?.value);
      const response = await LenderUgroService.modifyLeadOpsStatus(
        userType,
        formData,
        route
      );
      handleSuccessResponse(response);
    } catch (err) {
      // console.log(err);
      handleErrorResponse(err);
    }
  };

  const handleSuccessResponse = (response) => {
    if (response?.data?.status?.code === 1) {
      const success = response?.data?.messages?.success;
      const lender_id = response?.data?.data?.lender_id;
      toast.success(
        success?.form?.[0] || success?.others?.[0] || message.SAVED_SUCCESS
      );
      toggleModal(true);
      if (actionType === "approve" && route === "pre-sanction") {
        navigate("/portfolio/pre-sanction-approve");
      }
      if (actionType === "reject" && route === "pre-sanction") {
        navigate("/portfolio/pre-sanction-rejected");
      }
      if (actionType === "approve" && route === "disbursal" && ![21].includes(lender_id)) {
        navigate("/portfolio/in-disbursal-approve");
      }
      if (actionType === "reject" && route === "disbursal") {
        navigate("/portfolio/disbursal-rejected");
      }
      if ((actionType === "disburse" || (actionType === "approve" && [21].includes(lender_id))) && route === "disbursal") {
        navigate("/portfolio/disbursal-approved");
      }
    }
  };
  const handleErrorResponse = (err) => {
    let error = err?.response?.data?.messages?.error;
    toast.error(error?.form?.[0] || error?.others?.[0] || message.ERROR_FORM);
    setErrors(error);
  };
  const handleSanctionIds = (data) => {
    let Data_data = '';
    let max = data?.length;
    data?.map((ele, index) => {
      if (index === max - 1) {
        Data_data += ele.value
      } else {
        Data_data += `${ele.value},`
      }
    })
    setselectedReasons_name(Data_data)
  }

  const getModalTitle = () => {
    if (route == "pre-sanction" && type === "approve") {
      return "Approve Pre-Sanction Case"
    } else if (route == "disbursal" && (type === "approve" || type === "disburse")) {
      return "Approve (Disbursal) Case"
    } else if (route == "pre-sanction" && type === "reject") {
      return "Reject Pre-Sanction Case"
    } else if (route == "disbursal" && type === "reject") {
      return "Reject (Disbursal) Case"
    }
  }
  // console.log(reasons_name, "reasons name")
  return (
    <Modal state={state} closeModal={toggleModal} style={{ width: '450px' }} removeBorderSpace={true}>
      <div className="modal-header-container">
        <div className="modal-title">{getModalTitle()}</div>
        <div className="closeModalView" onClick={toggleModal}>
          <span className="closeModalIcon">x</span>
        </div>
      </div>
      <div className="p-4 modalData">
        <p>
          <b>
            {route == "pre-sanction" && type === "approve" &&
              window.location.pathname.indexOf("portfolio/view") !== -1 &&
              "Are you sure you want to pre-sanction this case?"}
            {route == "disbursal" && (type === "approve" || type === "disburse") &&
              window.location.pathname.indexOf("portfolio/view") !== -1 &&
              "Are you sure you want to disburse this case?"}
            {route == "pre-sanction" && type === "reject" &&
              window.location.pathname.indexOf("portfolio/view") !== -1 &&
              "Are you sure you want to Pre-Sanction reject this case?"}
            {route == "disbursal" && type === "reject" &&
              window.location.pathname.indexOf("portfolio/view") !== -1 &&
              "Are you sure you want to reject this case?"}
          </b>
        </p>
        <form onSubmit={formSubmitHandler}>
          {!actionType && (
            <div className=" form-group">
              <div className="row no-gutters">
                <div
                  class="custom-control custom-radio"
                  onClick={(e) => setOpsModalActionType("approve")}
                >
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="leadStatus"
                    value={1}
                    id={1}
                    checked={type === "approve"}
                  />
                  <label class="custom-control-label mt-1" for="defaultUnchecked">
                    Disburse
                  </label>
                </div>
                <div
                  class="custom-control custom-radio ml-2"
                  onClick={(e) => setOpsModalActionType("reject")}
                >
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="leadStatus"
                    value={1}
                    id={1}
                    checked={type === "reject"}
                  />
                  <label class="custom-control-label mt-1" for="defaultUnchecked">
                    Reject
                  </label>
                </div>
              </div>
            </div>
          )}
          {type === "reject" && (
            <>
              <div class="form-group">
                <Select
                  label={"Reason for Rejection"}
                  isMulti={false}
                  isClearable
                  onChange={(value) => setRejectType(value)}
                  options={reasonslist}
                  value={rejectType}
                />
              </div>
              <div class="form-group">
                <label>Upload File</label>
                <div class="row">
                  <div class="col-12">
                    <input
                      ref={inputRef}
                      file={file}
                      onChange={(e) => setFile(e.target.files[0])}
                      type="file"
                      className="form-control"
                    />
                  </div>
                </div>
                <Error errors={errors} id="file" />
              </div>
            </>
          )}
          {route == "pre-sanction" && type == "approve" &&


            <div class='form-group'>
              <div class='row'>
                <div class='col-12'>
                  <Select
                    label='Sanction condition'
                    className={'dropdown'}
                    options={reasonslist}
                    onChange={(data) => handleSanctionIds(data)}
                  // defaultValue={defaultProgram}
                  />
                </div>
              </div>
            </div>
          }
          <div class="form-group mt-2">
            <label>Comment</label>
            <textarea
              class="form-control custom-form-control"
              cols="2"
              rows="4"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <Error errors={errors} id="comment" />
          </div>
          {route == "pre-sanction" && type == "approve" &&
            <>
              <div class="form-group">
                <label>Upload File</label>
                <div class="row">
                  <div class="col-12">
                    <input
                      ref={inputRef}
                      file={file}
                      onChange={(e) => setFile(e.target.files[0])}
                      type="file"
                      className="form-control"
                    />
                  </div>
                </div>
                <Error errors={errors} id="file" />
              </div>

            </>

          }
          <div class="d-flex align-items-center">
            <button type="submit" class="btn btn-custom">
              {loading ? <div className="loader" /> : "OK"}
            </button>
            <button
              type="button"
              class="btn btn-outline-custom ml-2"
              data-dismiss="modal"
              onClick={toggleModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
