import React from 'react';
const TableHeader = ({ type }) => {
	return (
		<thead>
			<tr>
				<th>User ID</th>
				<th>User Name</th>
				{(type === 'affiliate' || type === 'lender') && (
					<>
						<th>Email</th>
						<th>Mobile No</th>
					</>
				)}

				{type === 'admin' && (
					<>
						<th>User Empoyee Id</th>
						{/* <th>Reporting Manager</th>
						<th>Reporting Manager Employee Id</th>

						<th>Branch</th> */}
						<th>Role</th>
						{/* <th>Business Type</th> */}
					</>
				)}
				<th>Last Login At</th>
				<th>Creation Date</th>
				<th>Updated At</th>
				<th>Status</th>
				<th>Action</th>
			</tr>
		</thead>
	);
};

export default TableHeader;
