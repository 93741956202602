import React, { useContext, useState } from "react";
import { logout } from "../services/auth";
import { Context } from "../config/store";
import { useRouter } from "../hooks/useRouter";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const { state, dispatch } = React.useContext(Context);
  const [showDropDown, setDropDownStatus] = React.useState(false);
  const router = useRouter();
  const [LafId, setlafid] = useState('');
  const navigate = useNavigate()

  const logoutUser = async (e) => {
    e.preventDefault();
    await logout({ type: state.userType });
    router.navigate(`/${state.dsaUser ? "dsa" : state.userType}/login`);
  };
  const checkHasPermission = (checkPermission) => {
    if (state.is_super_admin === 1) return true;
    const permission = state.permission.find(
      (permission) => permission === checkPermission
    );

    if (permission) return true;
    else return false;
  };
  const globalLafsearch = () => {
    if (LafId) {
      sessionStorage.setItem('globalLafId', LafId)
      let data = LafId
      setlafid('')
      router.navigate(`/portfolio/global-search?laf_id=${data}`)
    } else {
      toast.info('Please enter a LAF/LAN to search')
    }
  }

  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      globalLafsearch()
    }
  };
  React.useEffect(
    window.onload = function () {
      document.onclick = function (e) {
        if (e.target.id !== 'dropdownRef') {
          showDropDown && setDropDownStatus(false)
        }
      };
    }
  );
  return (
    <header className="header">
      <div className="container-fluid">
        <div className="row">
          <div style={{ cursor: 'pointer', display: 'flex', justifyContent: state.sidebarOpen ? 'left' : 'center', alignItems: 'center', width: state.sidebarOpen ? "226.5px" : "55px", background: '#fcfcfc', borderRadius: "0 40px 0 0" }}>
            <img src="/images/logo1.png" width={'68%'} onClick={() => { router.navigate('/portfolio/home') }} style={{ padding: state.sidebarOpen ? '10px' : '0px' }} />
          </div>
          <div className="" style={{ width: "16%" }}>
          </div>

          <div className="col d-flex align-items-center justify-content-end">
            <div className="globalLAFcontainer">
              <input
                className='globalLAFinput'
                type="text"
                maxLength={40}
                placeholder="Search Global LAF/LAN"
                onChange={(e) => setlafid(e.target.value.replace(/[_\W]+/g, ""))}
                value={LafId}
                onKeyDown={handleKeyDown}
              />
              <div className="searchIconsBox" style={{ cursor: 'pointer' }}>
                <i class="fa fa-search fa-lg" aria-hidden="true" onClick={globalLafsearch} style={{ color: "#757575" }}></i>
              </div>
            </div>
            {state.userType === "lender" &&
              checkHasPermission("view-notifications") && (
                <div className="notifications">
                  {" "}
                  <a
                    onClick={() =>
                      dispatch({
                        type: "showNotification",
                        showNotification: true,
                      })
                    }
                    className=""
                    type=""
                    data-toggle="offcanvas"
                  >
                  </a>
                </div>
              )}
            <div className={`dropdown user-login ${showDropDown ? "show" : ""}`} id="dropdownRef">
              <div
                className="user-dropdown dropdown-toggle d-flex align-items-center border-none justify-content-end"
                style={{ cursor: "pointer" }}
                onClick={() => setDropDownStatus(!showDropDown)}
                // type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="userProfile">
                  <div className="username">{state?.user?.f_name?.substring(0, 1)}</div>
                </div>
                <div className="userProfileDetail">
                  <b className=""> {state?.user?.f_name}</b>
                </div>
                {/* <i className="fa fa-user mr-2" aria-hidden="true"></i>{" "}
                    <span className="text-gray NewReg">
                      {" "}
                      <b className="text-gray"> {state?.user?.f_name}</b>
                    </span> */}
              </div>
              <div
                className={`DropdownLogout dropdown-menu ${showDropDown ? "show" : ""
                  }`}
                aria-labelledby="dropdownMenuButton"
              >
                {/* <a className='dropdown-item dropdownItem' href='#'>
									API Details
								</a>
								<a className='dropdown-item dropdownItem' href='#'>
									Update Profile
								</a>*/}
                <div
                  className="dropdown-item dropdownItem"
                  // href="/password/change"
                  onClick={() => navigate('/password/change')}
                >
                  Change Password
                </div>
                <a
                  className="dropdown-item dropdownItem "
                  href="#"
                  onClick={logoutUser}
                >
                  Log out
                </a>
              </div>
            </div>
            {/* <div className={`dropdown ${showDropDown ? 'show' : ''}`}>
							<button
								// onBlur={() => setDropDownStatus(false)}
								onClick={() => setDropDownStatus(!showDropDown)}
								className='btn user-dropdown dropdown-toggle'
								type='button'
								id='dropdownMenuButton'
								data-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded={showDropDown}
							>
								<i className='fa fa-user mr-2' aria-hidden='true' />
								{state.userType === 'dsa' ? (
									'DSA'
								) : (
									<b className='text-gray'> {state.user.f_name}</b>
								)}
							</button>
							<div
								className={`dropdown-menu ${showDropDown ? 'show' : ''}`}
								aria-labelledby='dropdownMenuButton'
							>
								<a className='dropdown-item' href='#'>
									Update Profile
								</a>
								<a className='dropdown-item' href='#'>
									Change Password
								</a>
								<a className='dropdown-item' onClick={logoutUser} href='#'>
									Logout
								</a>
							</div>
						</div> */}
          </div>
        </div>
        {/* <div className="col-2 my-2" style={{ cursor: 'pointer' }}>
            <img src="/images/logo1.png" width={'68%'} onClick={() => { router.navigate('/portfolio/home') }} />
          </div> */}
      </div>
    </header>
  );
};

export default Header;
